import * as React from 'react';
import * as Styled from './Select.styled';
import { Label } from '../Label';
import { ISelectProps } from './Select.types';
import { RequiredIndicator, InputHintMessage, InputErrorMessage } from '../Typography';

export function Select(props: ISelectProps): React.ReactElement {
    const {
        label,
        name,
        onChange,
        value,
        options,
        disabled,
        errorMessage,
        required,
        hint,
        noMargin,
        placeholder,
        vertical,
    } = props;

    const handleChanged = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange(name, event.target.value);
    };

    return (
        <Styled.Root noMargin={noMargin} vertical={vertical}>
            {label && (
                <Label>
                    {label} {required && <RequiredIndicator />}
                </Label>
            )}
            <Styled.SelectInput
                value={value || ''}
                onChange={handleChanged}
                disabled={disabled}
                hasError={!!errorMessage}
            >
                <option value="">{placeholder}</option>
                {options.map((o) => (
                    <option key={o.key} value={o.key}>
                        {o.value}
                    </option>
                ))}
            </Styled.SelectInput>

            {hint && <InputHintMessage>{hint}</InputHintMessage>}
            {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
        </Styled.Root>
    );
}
