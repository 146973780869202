import * as React from 'react';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import * as Layout from '../../Shared/Components/Layout';
import { Header } from '../../Shared/Components/Layout/Header';
import { useTrainings } from '../../Shared/Hooks/useTrainings';
import { Table } from '../../Shared/Components/Table';

export function TrainingSelectionScreen(): React.ReactElement {
    const trainings = useTrainings();

    const tableData = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        trainings?.forEach((m) => {
            result.push({
                name: m.name,
                selectLink: `/trainings/${m.id}`,
                selectLinkText: 'Select',
            });
        });

        return result;
    }, [trainings]);

    return (
        <Layout.Root>
            <Header />
            <Layout.ContentArea>
                <Layout.Main style={{ width: '100%' }}>
                    <Layout.Page>
                        <PageTitleDescription>Content Management</PageTitleDescription>
                        <PageTitle>Trainings - Testing Update</PageTitle>

                        <Table
                            isLoading={!tableData}
                            columns={[
                                { key: 'name', name: 'Name', style: { textAlign: 'left' } },
                                {
                                    key: 'select',
                                    name: 'Actions',
                                    type: 'link',
                                    linkKey: 'selectLink',
                                    linkText: 'selectLinkText',
                                    style: { textAlign: 'center' },
                                },
                            ]}
                            data={tableData}
                        />
                    </Layout.Page>
                </Layout.Main>
            </Layout.ContentArea>
        </Layout.Root>
    );
}
