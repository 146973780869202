import * as React from 'react';
import { useParams } from 'react-router-dom';
import { TelemetryDuration } from '../../Models/TelemetryDuration';
import { FreeTrialTrend } from '../../Shared/Components/Analytics/FreeTrialTrend';
import { SignUpTrend } from '../../Shared/Components/Analytics/SignUpTrend';
import { SubscriptionTrend } from '../../Shared/Components/Analytics/SubscriptionTrend';
import { Card } from '../../Shared/Components/Card';
import { Icon } from '../../Shared/Components/Icon';
import { Col, PageTitle, PageTitleDescription, Row } from '../../Shared/Components/Layout';
import { Select } from '../../Shared/Components/Select';
import { useUsers } from '../../Shared/Hooks/useUsers';
import * as Styled from '../UsersScreen/UserScreen.styled';

export function BusinessDashboardScreen(): React.ReactElement {
    const { trainingId } = useParams<{ trainingId: string }>();
    const [users, subStats] = useUsers(trainingId);
    const [time, setTime] = React.useState<string>('24h');

    const isUsersLoading = users === null;

    return (
        <>
            <Row noMargin>
                <Col size={1}>
                    <PageTitleDescription>Dashboards</PageTitleDescription>
                    <PageTitle>Engineering</PageTitle>
                </Col>
                <Col size={1}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            height: '100%',
                            paddingBottom: 30,
                        }}
                    >
                        <Select
                            noMargin
                            name="time"
                            value={time}
                            options={[
                                {
                                    key: TelemetryDuration['1HR'],
                                    value: 'Last 1 hour',
                                },
                                {
                                    key: TelemetryDuration['4HRS'],
                                    value: 'Last 4 hours',
                                },
                                {
                                    key: TelemetryDuration['12HRS'],
                                    value: 'Last 12 hours',
                                },
                                {
                                    key: TelemetryDuration['1Day'],
                                    value: 'Last 1 day',
                                },
                                {
                                    key: TelemetryDuration['3Days'],
                                    value: 'Last 3 days',
                                },
                                {
                                    key: TelemetryDuration['1Week'],
                                    value: 'Last 1 week',
                                },
                                {
                                    key: TelemetryDuration['2Weeks'],
                                    value: 'Last 2 weeks',
                                },
                                {
                                    key: TelemetryDuration['30Days'],
                                    value: 'Last 1 month',
                                },
                                {
                                    key: TelemetryDuration['60Days'],
                                    value: 'Last 2 months',
                                },
                                {
                                    key: TelemetryDuration['90Days'],
                                    value: 'Last 3 months',
                                },
                            ]}
                            onChange={(name, value) => setTime(value)}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col size={1}>
                    <Card>
                        <Styled.MainCard>
                            <Styled.MainCardTitle>Active subscribers</Styled.MainCardTitle>
                            {isUsersLoading && <Styled.SubscriberCount>--</Styled.SubscriberCount>}
                            {!isUsersLoading && (
                                <Styled.SubscriberCount>{subStats.active}</Styled.SubscriberCount>
                            )}
                            <Styled.MainIconRoot>
                                <Icon name="person-check" size={30} />
                            </Styled.MainIconRoot>
                        </Styled.MainCard>
                    </Card>
                </Col>
                <Col size={3}>
                    <Card>
                        <Styled.SubCard>
                            <Styled.SubCardSection>
                                <Styled.SubIconRoot>
                                    <Icon name="people" size={30} />
                                </Styled.SubIconRoot>
                                {isUsersLoading && <Styled.SubCardCount>--</Styled.SubCardCount>}
                                {!isUsersLoading && (
                                    <Styled.SubCardCount>{subStats.total}</Styled.SubCardCount>
                                )}
                                <Styled.SubCardCountText>All accounts</Styled.SubCardCountText>
                            </Styled.SubCardSection>
                            <Styled.SubCardSection>
                                <Styled.SubIconRoot>
                                    <Icon name="person-plus" size={30} />
                                </Styled.SubIconRoot>
                                {isUsersLoading && <Styled.SubCardCount>--</Styled.SubCardCount>}
                                {!isUsersLoading && (
                                    <Styled.SubCardCount>{subStats.trial}</Styled.SubCardCount>
                                )}
                                <Styled.SubCardCountText>Free trial</Styled.SubCardCountText>
                            </Styled.SubCardSection>
                            <Styled.SubCardSection>
                                <Styled.SubIconRoot>
                                    <Icon name="person-dash" size={30} />
                                </Styled.SubIconRoot>
                                {isUsersLoading && <Styled.SubCardCount>--</Styled.SubCardCount>}
                                {!isUsersLoading && (
                                    <Styled.SubCardCount>{subStats.expired}</Styled.SubCardCount>
                                )}
                                <Styled.SubCardCountText>
                                    Expired subscriptions
                                </Styled.SubCardCountText>
                            </Styled.SubCardSection>
                            <Styled.SubCardSection>
                                <Styled.SubIconRoot>
                                    <Icon name="person-x" size={30} />
                                </Styled.SubIconRoot>
                                {isUsersLoading && <Styled.SubCardCount>--</Styled.SubCardCount>}
                                {!isUsersLoading && (
                                    <Styled.SubCardCount>{subStats.noSub}</Styled.SubCardCount>
                                )}
                                <Styled.SubCardCountText>Never subscribed</Styled.SubCardCountText>
                            </Styled.SubCardSection>
                        </Styled.SubCard>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col size={1}>
                    <SubscriptionTrend time={time} trainingId={trainingId} />
                </Col>
                <Col size={1}>
                    <Row>
                        <Col size={1}>
                            <SignUpTrend time={time} trainingId={trainingId} />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={1}>
                            <FreeTrialTrend time={time} trainingId={trainingId} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
