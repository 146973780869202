import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { AppConfig } from '../../../App.config';
import { IFile } from '../../../Models/IFile';
import { Http } from '../../Clients/Http';
import { Button } from '../Button';
import { Label } from '../Label';
import { Spinner } from '../Spinner';
import { InputErrorMessage, InputHintMessage, RequiredIndicator } from '../Typography';
import * as Styled from './Uploader.styled';
import { IContentUploaderProps, UploadContentType } from './Uploader.types';
import { readFile } from './Uploader.utils';
import { Blob } from '../../Clients/Blob';

export function ContentUploader(props: IContentUploaderProps): React.ReactElement {
    const {
        value,
        onChange,
        label,
        required,
        hint,
        errorMessage,
        contentType,
        name,
        containerName,
    } = props;
    const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
    const [newFile, setNewFile] = React.useState<IFile | null>(null);
    const [newLength, setLength] = React.useState<number | null>(null);
    const audioRef = React.useRef<HTMLAudioElement | null>(null);
    const videoRef = React.useRef<HTMLVideoElement | null>(null);
    const ref = React.useRef<HTMLInputElement | null>(null);
    const [isUploading, setUploading] = React.useState<boolean>(false);

    console.log('AppConfig.endpoints.cdn.url =>', AppConfig.endpoints.cdn.url);

    React.useEffect(() => {
        if (!value) return;

        if (!containerName) {
            Http.request<string>({
                url: `${AppConfig.endpoints.admin.url}/contents/${value}/url`,
            }).then((data) => {
                setPreviewUrl(data);
            });
        } else {
            setPreviewUrl(`${AppConfig.endpoints.cdn.url}/${containerName}/${value}`);
        }
    }, [value, containerName]);

    const handleFileSelected = (): void => {
        if (!ref.current) return;
        if (ref.current?.files?.length === 0) return;

        setUploading(true);

        const file = ref.current!.files![0];

        readFile(file)
            .then(setNewFile)
            .catch(() => alert('Failed to read file'))
            .finally(() => setUploading(false));
    };

    const handleBrowseButtonClicked = (): void => {
        if (!ref.current) return;

        ref.current.click();
    };

    const handleUploadButtonClicked = async (): Promise<void> => {
        if (!newFile || !newFile.base64) return;
        if (!newLength) return;

        setUploading(true);

        try {
            const fileName = `${uuid()}.${newFile?.extension}`;
            await Blob.upload(containerName || 'visualizations', fileName, newFile.base64);

            onChange(name, fileName, parseInt(newLength.toString()));
        } catch (e) {
            console.log(e);
            alert('Upload failed');
        } finally {
            setNewFile(null);
            setLength(null);
            setUploading(false);
        }
    };

    const handleDeleted = (): void => {
        onChange(name, null, null);
        setNewFile(null);
        setLength(null);
        setPreviewUrl(null);
    };

    const handleMetadataReceived = (): void => {
        if (audioRef.current) setLength(audioRef.current?.duration);
        if (videoRef.current) setLength(videoRef.current?.duration);
    };

    const getAcceptedFiles = (): string => {
        switch (contentType) {
            case UploadContentType.Audio:
                return 'audio/mpeg, audio/mp3';
            case UploadContentType.Video:
                return 'video/mpeg, video/mp4';
            default:
                return '';
        }
    };

    return (
        <Styled.Root>
            {label && (
                <Label>
                    {label} {required && <RequiredIndicator />}
                </Label>
            )}
            {hint && <InputHintMessage style={{ marginBottom: 10 }}>{hint}</InputHintMessage>}
            {errorMessage && (
                <InputErrorMessage style={{ marginBottom: 10 }}>{errorMessage}</InputErrorMessage>
            )}

            {contentType === UploadContentType.Audio && (newFile || previewUrl) && (
                <audio
                    ref={audioRef}
                    controls
                    style={{ marginBottom: 20 }}
                    onLoadedMetadata={handleMetadataReceived}
                    onChange={handleMetadataReceived}
                    src={newFile?.base64 || previewUrl || ''}
                />
            )}
            {contentType === UploadContentType.Video && (newFile || previewUrl) && (
                <video
                    ref={videoRef}
                    controls
                    style={{ marginBottom: 20 }}
                    src={newFile?.base64 || previewUrl || ''}
                    onLoadedMetadata={handleMetadataReceived}
                    onChange={handleMetadataReceived}
                    width={400}
                    height={300}
                />
            )}

            <Styled.ControlRoot>
                {!newFile && (
                    <Button onClick={handleBrowseButtonClicked} text="Browse" secondary noMargin />
                )}
                {newFile && <Button onClick={handleUploadButtonClicked} text="Upload" noMargin />}
                {(value || newFile) && <Button onClick={handleDeleted} text="Delete" danger />}
            </Styled.ControlRoot>

            <Styled.HiddenInput
                ref={ref}
                type="file"
                accept={getAcceptedFiles()}
                onChange={handleFileSelected}
            />

            {isUploading && <Spinner />}
        </Styled.Root>
    );
}
