import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { ITrainingContent } from '../../Models/ITrainingContent';
import { ITrainingContentUpdateModel } from '../../Models/ITrainingContentUpdateModel';
import { Http } from '../Clients/Http';

export function useTrainingContent(
    trainingId: string,
    id?: string
): [
    ITrainingContent | null,
    ITrainingContentUpdateModel,
    Dispatch<SetStateAction<ITrainingContentUpdateModel>>
] {
    const [visualization, setVisualization] = useState<ITrainingContent | null>(null);
    const [update, setUpdate] = useState<ITrainingContentUpdateModel>({
        name: '',
        id: null,
        trainingId,
        description: '',
        mentalSkillId: null,
        competitiveSkillId: null,
        tags: [],
        visualizationType: 'Practice',
        visualizationAuthor: 'Will Johnston',
        authorImage:
            'https://prodapeak.blob.core.windows.net/author-images/202309_superiormind_Will_Johnston.png',
        matchVisualizationType: 'Singles',
        backgroundImage: null,
        situations: [],
        visualizationContents: [],
        focusedVisualizationContents: [],
        subscriptionLevel: 0,
        bookAuthor: null,
    });

    useEffect(() => {
        if (!id) return;

        Http.request<ITrainingContent>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/contents/${id}`,
        })
            .then((data) => {
                setVisualization(data);
                setUpdate((p: ITrainingContentUpdateModel) => ({
                    ...p,
                    ...data,
                }));
            })
            .catch(console.log);
    }, [id, trainingId]);

    return [visualization, update, setUpdate];
}
