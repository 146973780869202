import styled from 'styled-components';

export const Root = styled.div`
    margin-bottom: 22px;
`;

export const List = styled.div`
    display: flex;
    margin-top: 22px;
`;

export const ItemRoot = styled.div`
    margin-right: 12px;
    cursor: pointer;
`;

export const Preview = styled.img`
    width: 100px;
    height: 100px;
`;

export const HiddenTextArea = styled.input``;
