import styled from 'styled-components';

export const Root = styled.div`
    margin-bottom: 32px;
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const ControlRoot = styled.div`
    display: flex;
    align-items: center;
`;

export const ImagePreview = styled.img`
    width: 375px;
    height: 812px;
    margin-bottom: 10px;
    object-fit: cover;
    border-radius: 20px;
`;

export const File = styled.div`
    margin-left: 15px;
    font-size: 12px;
    background-color: #8c8c8c;
    border-radius: 8px;
    line-height: 26px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
`;

export const DeleteButton = styled.button`
    background: none;
    border: none;
    color: white;
    margin-left: 15px;
`;
