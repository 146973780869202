export enum TelemetryDuration {
    '1HR' = '1h',
    '4HRS' = '4h',
    '12HRS' = '12h',
    '1Day' = '24h',
    '3Days' = '72h',
    '1Week' = '168h',
    '2Weeks' = '336h',
    '30Days' = '720h',
    '60Days' = '1440h',
    '90Days' = '2160h',
}

export function getTick(time: string): string {
    let tick = '30m';

    switch (time) {
        case TelemetryDuration['1HR']:
            tick = '5m';
            break;
        case TelemetryDuration['4HRS']:
            tick = '10m';
            break;
        case TelemetryDuration['12HRS']:
            tick = '30m';
            break;
        case TelemetryDuration['1Day']:
            tick = '1h';
            break;
        case TelemetryDuration['3Days']:
            tick = '3h';
            break;
        case TelemetryDuration['1Week']:
            tick = '6h';
            break;
        case TelemetryDuration['2Weeks']:
            tick = '12h';
            break;
        case TelemetryDuration['30Days']:
            tick = '24h';
            break;
        case TelemetryDuration['60Days']:
            tick = '48h';
            break;
        case TelemetryDuration['90Days']:
            tick = '72h';
            break;
    }

    return tick;
}
