import * as React from 'react';
import * as Styled from './MainHeader.styled';
import { IMainHeaderProps } from './MainHeader.types';

export function MainHeader(props: IMainHeaderProps): React.ReactElement {
    const { renderLeft } = props;

    return (
        <Styled.Root>
            <Styled.Left>{renderLeft()}</Styled.Left>
        </Styled.Root>
    );
}
