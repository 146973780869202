import { useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { ICoach } from '../../Models/ICoach';
import { Http } from '../Clients/Http';

export function useCoaches() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [coaches, setCoaches] = useState<ICoach[]>([]);

    useEffect(() => {
        Promise.all([
            Http.request({
                url: `${AppConfig.endpoints.admin.url}/coaches`,
            }),
        ])
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((data: any) => {
                setCoaches(data[0]);
            })
            .catch(() => {
                // CacheClient.remove(usersCacheKey);
            });
    }, []);

    return [coaches];
}
