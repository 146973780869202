import styled from 'styled-components';
import { Color } from '../../Shared/Color';

export const MainCard = styled.div`
    background-color: rgb(230, 244, 255);
    width: 100%;
    height: 180px;
    padding: 30px;
    position: relative;
`;

export const MainCardTitle = styled.div`
    font-weight: 700;
    font-size: 1.125em;
`;

export const SubscriberCount = styled.div`
    font-weight: 500;
    font-size: 4em;
    color: ${Color.Primary};
`;

export const MainIconRoot = styled.div`
    position: absolute;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    background-color: rgb(30, 77, 183);
    color: white;
    text-align: center;
`;

export const SubCard = styled.div`
    height: 180px;
    background-color: white;
    flex: 1;
    display: flex;
`;

export const SubCardSection = styled.div`
    position: relative;
    display: flex;
    width: 25%;
    height: 100%;
    padding: 30px;
    border-right: 1px solid ${Color.DefaultBorder};
    justify-content: flex-end;
    flex-direction: column;

    &:last-of-type {
        border-right: none;
    }
`;

export const SubCardCount = styled.div`
    font-weight: 500;
    font-size: 1.5em;
    color: ${Color.DefaultText};
`;

export const SubCardCountText = styled.div`
    color: ${Color.SubText};
`;

export const SubIconRoot = styled.div`
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    margin-bottom: 20px;
    background-color: rgb(230, 244, 255);
    color: rgb(27, 151, 245);
    text-align: center;
`;
