import styled from 'styled-components';

export const DataGroup = styled.div`
    margin-bottom: 32px;
    font-weight: 600;
`;

export const Label = styled.div`
    font-weight: 500;
    margin-bottom: 4px;
    color: #666;
`;
