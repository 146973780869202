import { NavLink } from 'react-router-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Color } from '../../Color';

export const Root = styled.table<{ dense?: boolean }>`
    background-color: white;
    width: 100%;
    border-spacing: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    & tbody tr {
        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }
    }

    & tbody tr:last-of-type td {
        border-bottom: none;
    }

    & th {
        font-size: 16px;
        line-height: 24px;
        height: 50px;
        padding-left: 17px;
        padding-right: 17px;
        border-bottom: 1px solid rgb(224, 224, 224);
    }

    & td {
        height: 75px;
        font-size: 16px;
        line-height: 24px;
        padding-left: 17px;
        padding-right: 17px;
        border-bottom: 1px solid rgb(224, 224, 224);
    }

    ${({ dense }): FlattenSimpleInterpolation | false | undefined =>
        dense &&
        css`
            & th {
                height: 30px;
            }

            & td {
                height: 48px;
            }
        `}
`;

export const CountLink = styled(NavLink)`
    background-color: #eee;
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    color: #0a84ff;
`;

export const LinkButton = styled.button`
    border: none;
    background: none;
    color: #0a84ff;
    font-weight: 600;
    font-size: 16px;

    &:disabled {
        color: #ccc;
    }
`;

export const NoData = styled.div`
    width: 100%;
    margin-top: 200px;
    margin-bottom: 200px;
    text-align: center;
    color: ${Color.SubText};
`;
