import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as React from 'react';
import { IMentalSkill, ITraining } from '../../Models/ITraining';
import { IMentalSkillUpdateModel } from '../../Models/ITrainingUpdateModel';
import { useTraining } from './useTraining';

export function useMentalSkill(
    trainingId: string,
    mentalSkillId?: string
): [
    ITraining | null,
    IMentalSkill | null,
    IMentalSkillUpdateModel,
    Dispatch<SetStateAction<IMentalSkillUpdateModel>>
] {
    const training = useTraining(trainingId);
    const [mentalSKill, setMentalSkill] = useState<IMentalSkill | null>(null);
    const [update, setUpdate] = React.useState<IMentalSkillUpdateModel>({
        id: null,
        name: '',
        assessmentName: '',
        type: 'Practice',
        weight: 0,
        requiredIterations: 10,
        assessmentQuestion: '',
        reinforcementType: 'Single',
        reinforcementTitles: {},
        reinforcementChoiceQuestion: null,
        reinforcementChoices: {},
        backgroundImageUrl: '',
        backgroundImage: null,
    });

    useEffect(() => {
        if (mentalSkillId) {
            const m = training?.mentalSkills?.find((s: IMentalSkill) => s.id === mentalSkillId);
            setMentalSkill(m || null);
            setUpdate(
                (prev): IMentalSkillUpdateModel => ({
                    ...prev,
                    ...m,
                })
            );
        }
    }, [training, mentalSkillId]);

    return [training, mentalSKill, update, setUpdate];
}
