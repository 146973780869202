import * as React from 'react';
import * as Styled from './TextInput.styled';
import { Label } from '../Label';
import { ITextInputProps } from './TextInput.types';
import { RequiredIndicator, InputErrorMessage, InputHintMessage } from '../Typography';

export function TextInput(props: ITextInputProps): React.ReactElement {
    const {
        label,
        name,
        onChange,
        value,
        errorMessage,
        required,
        hint,
        placeholder,
        noMargin,
        multiline,
        width,
    } = props;

    const handleChanged = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        onChange(name, event.target.value);
    };

    return (
        <Styled.Root noMargin={noMargin}>
            {label && (
                <Label>
                    {label} {required && <RequiredIndicator />}
                </Label>
            )}

            {multiline && (
                <Styled.MultilineInput
                    value={value}
                    onChange={handleChanged}
                    hasError={!!errorMessage}
                    placeholder={placeholder}
                />
            )}
            {!multiline && (
                <Styled.Input
                    width={width}
                    value={value}
                    onChange={handleChanged}
                    hasError={!!errorMessage}
                    placeholder={placeholder}
                />
            )}

            {hint && <InputHintMessage>{hint}</InputHintMessage>}
            {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
        </Styled.Root>
    );
}
