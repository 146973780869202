import * as React from 'react';
import * as Styled from './Button.styled';
import { IButtonProps } from './Button.types';

export function Button(props: IButtonProps): React.ReactElement {
    const { onClick, text, noMargin, secondary, danger, reject, disable } = props;

    return (
        <Styled.Root
            onClick={onClick}
            noMargin={noMargin}
            secondary={secondary}
            danger={danger}
            reject={reject}
            disabled={disable}
        >
            {text}
        </Styled.Root>
    );
}
