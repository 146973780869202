import { useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { Engagement } from '../../Models/Engagement';
import { Http } from '../Clients/Http';

export function useTraineeEngagements(trainingId: string, userId: string): Engagement[] | null {
    const [state, setState] = useState<Engagement[] | null>(null);

    useEffect(() => {
        Http.request<Engagement[]>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${userId}/engagements`,
        }).then(setState);
    }, [trainingId, userId]);

    return state;
}
