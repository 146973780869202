export interface IUploaderProps {
    label?: string;
    name: string;
    value: string | null | undefined;
    hint?: string;
    required?: boolean;
    errorMessage?: string;

    onChange(name: string, value: string): void;
}

export interface IContentUploaderProps extends Omit<IUploaderProps, 'onChange'> {
    containerName?: string;
    length?: number;
    onChange(name: string, value: string | null, length: number | null): void;
    contentType: UploadContentType;
}

export enum UploadContentType {
    Video = 'Video',
    Audio = 'Audio',
}
