import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Color } from '../../../Color';

export const Root = styled.nav`
    margin: 30px 0 30px 30px;
    width: 265px;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
`;

export const MenuHeading = styled.div`
    font-size: 0.875em;
    color: ${Color.SubText};
    text-transform: uppercase;
    margin-bottom: 20px;
`;

export const MenuList = styled.ul`
    padding-left: 0;
    list-style: none;
    margin-bottom: 30px;
`;

export const MenuItem = styled.li`
    margin-bottom: 10px;
`;

export const MenuItemLink = styled(NavLink)`
    display: block;
    border-left: 3px solid transparent;
    font-size: 16px;
    height: 48px;
    line-height: 32px;
    color: ${Color.DefaultText};
    padding: 8px 12px;
    border-radius: 8px;

    &.active {
        background-color: ${Color.Primary};
        font-weight: 600;
        color: white;
    }
`;
