class SessionStorage {
    public set(key: string, value: string) {
        window.sessionStorage.setItem(key, value);
    }

    public get(key: string): string | null {
        return window.sessionStorage.getItem(key);
    }

    public clear(key: string) {
        window.sessionStorage.removeItem(key);
    }

    public clearAll() {
        window.sessionStorage.clear();
    }
}

const sessionStorage = new SessionStorage();
export { sessionStorage as SessionStorage };
