import { AppConfig } from '../../App.config';
import { ITrainingUpdateModel } from '../../Models/ITrainingUpdateModel';
import { Http } from '../Clients/Http';

export async function saveTraining(training: ITrainingUpdateModel): Promise<void> {
    return new Promise(
        async (resolve, reject): Promise<void> => {
            try {
                await Http.request({
                    url: `${AppConfig.endpoints.admin.url}/trainings`,
                    method: 'post',
                    data: training,
                });

                resolve();
            } catch (e) {
                reject(e);
            }
        }
    );
}
