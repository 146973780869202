import * as React from 'react';
import styled from 'styled-components';
import { Header } from './Header';
import { Nav } from './Nav';

export const Root = styled.div`
    height: 100%;
`;

export const ContentArea = styled.div`
    display: flex;
    height: calc(100% - 70px);
`;

export const Main = styled.main`
    width: calc(100% - 265px);
    overflow: auto;
`;

export const Page = styled.div`
    width: 1275px;
    overflow: hidden;
    margin: 30px auto;
`;

export function Layout(props: React.PropsWithChildren<{}>): React.ReactElement {
    return (
        <Root>
            <Header />
            <ContentArea>
                <Nav />
                <Main>
                    <Page>{props.children}</Page>
                </Main>
            </ContentArea>
        </Root>
    );
}
