import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as React from 'react';
import { ICompetitiveSkill, ITraining } from '../../Models/ITraining';
import { ICompetitiveSkillUpdateModel } from '../../Models/ITrainingUpdateModel';
import { useTraining } from './useTraining';

export function useCompetitiveSkill(
    trainingId: string,
    competitiveSkillId?: string
): [
    ITraining | null,
    ICompetitiveSkill | null,
    ICompetitiveSkillUpdateModel,
    Dispatch<SetStateAction<ICompetitiveSkillUpdateModel>>
] {
    const training = useTraining(trainingId);
    const [competitiveSkill, setCompetitiveSkill] = useState<ICompetitiveSkill | null>(null);
    const [update, setUpdate] = React.useState<ICompetitiveSkillUpdateModel>({
        id: null,
        name: '',
        type: 'Singles',
        backgroundImageUrl: '',
        reinforcementStatement: '',
        backgroundImage: null,
    });

    useEffect(() => {
        if (competitiveSkillId) {
            const m = training?.competitiveSkills?.find(
                (s: ICompetitiveSkill) => s.id === competitiveSkillId
            );
            setCompetitiveSkill(m || null);
            setUpdate(
                (prev): ICompetitiveSkillUpdateModel => ({
                    ...prev,
                    ...m,
                })
            );
        }
    }, [training, competitiveSkillId]);

    return [training, competitiveSkill, update, setUpdate];
}
