import styled from 'styled-components';
import { Color } from '../../Shared/Color';

export const Root = styled.main`
    display: flex;
    height: 100%;
`;

export const Heading = styled.h1`
    font-size: 1.875rem;
    line-height: 1.235;
    margin-top: 0;
    margin-bottom: 50px;
    font-weight: 700;
`;

export const SubHeading = styled.h2`
    font-size: 0.875rem;
    line-height: 1.235;
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 500;
    color: ${Color.SubText};
`;

export const Login = styled.div`
    width: 360px;
    margin: auto;
`;

export const SignInButton = styled.button.attrs({
    type: 'button',
})`
    background: ${Color.Primary};
    border-radius: 4px;
    height: 54px;
    width: 100%;
    border: 1px solid ${Color.Primary};
    color: white;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-transform: uppercase;
`;
