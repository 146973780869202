import { useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { ISubscriptionQueryModel } from '../../Models/ISubscriptionQueryModel';
import { IUserQueryModel } from '../../Models/IUserQueryModel';
import { IUserSubscription } from '../../Models/IUserSubscription';
import { CacheClient } from '../Clients/CacheClient';
import { Http } from '../Clients/Http';

export interface ISubscriptionStats {
    total: number;
    active: number;
    trial: number;
    expired: number;
    noSub: number;
}

const usersCacheKey = 'apeakadmin_users';
const subStatsCacheKey = 'apeakadmin_subStats';

export function useUsers(trainingId: string): [IUserSubscription[] | null, ISubscriptionStats] {
    const [users, setUsers] = useState<IUserSubscription[] | null>(
        CacheClient.get<IUserSubscription[]>(usersCacheKey)
    );
    const [stats, setStats] = useState<ISubscriptionStats>(
        CacheClient.get<ISubscriptionStats>(subStatsCacheKey) || {
            total: 0,
            active: 0,
            trial: 0,
            expired: 0,
            noSub: 0,
        }
    );

    useEffect(() => {
        Promise.all([
            Http.request<IUserQueryModel[]>({
                url: `${AppConfig.endpoints.identity.url}/admin/account`,
            }),
            Http.request<ISubscriptionQueryModel[]>({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/subscriptions`,
            }),
        ])
            .then((data) => {
                const results: IUserSubscription[] = [];
                const users = data[0];
                const subscriptions = data[1];
                const subscriptionMap: { [key: string]: ISubscriptionQueryModel } = {};
                subscriptions.forEach((p) => {
                    subscriptionMap[p.userId] = p;
                });

                const subStats: ISubscriptionStats = {
                    total: 0,
                    active: 0,
                    trial: 0,
                    expired: 0,
                    noSub: 0,
                };

                users.forEach((p) => {
                    const subscription = subscriptionMap[p.userId];
                    const subscriptionStatus =
                        subscription?.subscriptionStatus?.toUpperCase() ?? 'N/A';
                    const store = subscription?.store?.toUpperCase() ?? 'N/A';
                    const subEndDate =
                        subscription?.subscriptionRenewalDate ||
                        subscription?.subscriptionEndDate ||
                        null;

                    switch (subscriptionStatus) {
                        case 'ACTIVE':
                            subStats.active++;
                            break;
                        case 'FREETRIAL':
                            subStats.trial++;
                            break;
                        case 'EXPIRED':
                            subStats.expired++;
                            break;
                        case 'N/A':
                            subStats.noSub++;
                            break;
                    }

                    subStats.total++;

                    results.push({
                        ...p,
                        store,
                        subscriptionStatus,
                        isRenewing: subscription?.isAutoRenewing ? 'Y' : 'N',
                        subEndDate: subEndDate ? new Date(subEndDate).toLocaleDateString() : 'N/A',
                        isFreeAccount: subscription?.isFreeAccount ? 'Y' : 'N',
                        promotionCode: subscription?.promotionCode,
                    });
                });

                setUsers(results);
                setStats(subStats);

                CacheClient.set(usersCacheKey, results);
                CacheClient.set(subStatsCacheKey, subStats);
            })
            .catch(() => {
                CacheClient.remove(usersCacheKey);
            });
    }, [trainingId]);

    return [users, stats];
}
