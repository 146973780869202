import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Color } from '../../Color';
import { SectionTitle } from '../Layout';

export const Heading = styled.div<{ bottom?: string }>`
    z-index: 100;
    padding: 20px 30px 15px;

    ${({ bottom }) =>
        bottom &&
        css`
            padding-bottom: ${bottom};
        `}
`;

export const Title = styled(SectionTitle)`
    margin-bottom: 0;
    padding-top: 0;
`;

export const Description = styled.p`
    color: ${Color.SubText};
`;

export const Root = styled.div<{ height: number }>`
    padding: 15px;
    overflow: auto;

    ${({ height }): FlattenSimpleInterpolation => css`
        height: ${height}px;
    `}
`;

export const GraphRoot = styled.div<{ height: number }>`
    width: calc(100% - 30px);
    position: absolute;
    bottom: 15px;
    left: 15px;

    ${({ height }): FlattenSimpleInterpolation => css`
        height: ${height}px;
    `}
`;

export const Figures = styled.div`
    display: flex;
`;

export const FigureRoot = styled.div`
    margin-right: 40px;
`;

export const Figure = styled.div<{ color: string }>`
    font-weight: 800;
    font-size: 2em;
    color: ${({ color }): string => color};
`;

export const FigureDescirption = styled.div`
    font-size: 0.8em;
    font-weight: 600;
    color: gray;
`;
