import * as React from 'react';
import * as Styled from './Spinner.styled';

export function Spinner(props: { hideOverlay?: boolean }): React.ReactElement {
    const { hideOverlay } = props;

    return (
        <>
            {!hideOverlay && (
                <>
                    <Styled.Overlay />
                    <Styled.Root>
                        <Styled.Spinner />
                    </Styled.Root>
                </>
            )}
            {hideOverlay && (
                <Styled.StandaloneRoot>
                    <Styled.Spinner color="#efefef" />
                </Styled.StandaloneRoot>
            )}
        </>
    );
}
