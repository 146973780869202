import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { ICoachVideoQueryModel } from '../../Models/ICoachVideoQueryModel';
import { ICoachVideoUpdateModel } from '../../Models/ICoachVideoUpdateModel';
import { Http } from '../Clients/Http';

export function useCoachVideo(
    trainingId: string,
    coachVideoId?: string
): [ICoachVideoQueryModel, Dispatch<SetStateAction<ICoachVideoQueryModel>>] {
    const [update, setUpdate] = useState<ICoachVideoUpdateModel>({
        id: null,
        trainingId,
        title: '',
        backgroundImageUrl: '',
        videoThumbnailUrl: '',
        linkedVideoUrl: '',
        organicVideoUrl: '',
        description: '',
        contentDate: new Date(),
        attachments: [],
        backgroundImage: null,
        organicVideo: null,
        videoThumbnail: null,
    });

    useEffect(() => {
        if (coachVideoId) {
            Http.request<ICoachVideoQueryModel>({
                url: `${AppConfig.endpoints.admin.url}/team-resources/${coachVideoId}`,
            }).then((coachVideo: ICoachVideoQueryModel): void => {
                setUpdate((p) => ({
                    ...p,
                    ...coachVideo,
                }));
            });
        }
    }, [coachVideoId, trainingId]);

    return [update, setUpdate];
}
