export enum GrantType {
    External = 'external',
    Refresh = 'refresh_token',
}

export enum AuthCacheKey {
    AccessToken = 'AccessToken',
    RefreshToken = 'RefreshToken',
    AccessTokenExpiration = 'AccessTokenExpiration',
}

export enum TokenStatus {
    NoToken = 'NoToken',
    ExpiredToken = 'ExpiredToken',
    ValidToken = 'ValidToken',
}

export interface ILoginInput {
    email: string;
    password: string;
}

export interface ITokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
}
