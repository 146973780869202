import * as React from 'react';
import * as Styled from './Checkbox.styled';
import { ICheckboxProps } from './Checkbox.types';

export function Checkbox(props: ICheckboxProps): React.ReactElement {
    const { name, label, value, disabled, onChange, hasError, size, color } = props;

    const handlePressed = (): void => {
        if (!onChange) return;

        onChange(name, !value);
    };

    return (
        <Styled.Root onClick={handlePressed} disabled={disabled}>
            <Styled.CheckboxRoot isChecked={value} hasError={hasError} size={size} color={color}>
                {value && <Styled.Check src={require('./checkmark.png')} />}
            </Styled.CheckboxRoot>
            {label && <Styled.Label>{label}</Styled.Label>}
        </Styled.Root>
    );
}
