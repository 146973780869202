import * as React from 'react';
import { Button } from '../../../Shared/Components/Button';
import * as Styled from './ResourcePreview.styled';
import { IResourcePreviewProps } from './ResourcePreview.types';

export function ResourcePreview(props: IResourcePreviewProps): React.ReactElement {
    const { html, type, close } = props;

    return (
        <Styled.Overlay onClick={close}>
            <Styled.Root>
                <Styled.CloseRoot>
                    <Button text="Close preview" onClick={close} />
                </Styled.CloseRoot>
                <Styled.PreviewRoot
                    className={type === 'mobile' ? 'mobile-view' : 'desktop-view'}
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                />
            </Styled.Root>
        </Styled.Overlay>
    );
}
