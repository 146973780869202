import styled from 'styled-components';
import { Color } from '../../Color';

export const Root = styled.div`
    margin-bottom: 22px;
`;

export const InputRoot = styled.div`
    height: 38px;
    font-size: 15px;
    display: flex;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid ${Color.DefaultBorder};
    width: 600px;
`;

export const TagList = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const Tag = styled.li`
    height: 26px;
    border-radius: 8px;
    background-color: #44b65d;
    margin-right: 10px;
    color: white;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 26px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 8px;
    display: flex;
    font-weight: 500;
    cursor: pointer;
`;

export const TagInput = styled.input`
    border-radius: 8px;
    border: none;
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
`;
