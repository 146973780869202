import * as React from 'react';
import * as Styled from './LoginTextField.styled';
import { ILoginTextFieldProps } from './LoginTextField.types';

export function LoginTextField(props: ILoginTextFieldProps): React.ReactElement {
    const { name, value, label, onChange, placeholder, password, marginBottom } = props;

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;

        onChange(name, value);
    };

    return (
        <Styled.Root marginBottom={marginBottom}>
            <Styled.Label>{label}</Styled.Label>
            <Styled.Input
                value={value}
                onChange={handleChanged}
                placeholder={placeholder}
                type={password ? 'password' : 'text'}
            />
        </Styled.Root>
    );
}
