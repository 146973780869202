import * as React from 'react';
import { AppConfig } from '../../App.config';
import { IResourceQueryModel } from '../../Models/IResourceQueryModel';
import { Http } from '../Clients/Http';

export function useCoachVideos(trainingId: string): [IResourceQueryModel[]] {
    const [coachVideos, setCoachVideos] = React.useState<IResourceQueryModel[]>([]);

    React.useEffect(() => {
        Http.request<IResourceQueryModel[]>({
            url: `${AppConfig.endpoints.admin.url}/team-resources`,
        }).then((data) => {
            setCoachVideos(data);
        });
    }, [trainingId]);

    return [coachVideos];
}
