import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { IFile } from '../../Models/IFile';
import { ValidationErrors } from '../../Models/ValidationErrors';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { MainHeader } from '../../Shared/Components/Layout/MainHeader';
import { Section } from '../../Shared/Components/Section';
import { Spinner } from '../../Shared/Components/Spinner';
import { TextInput } from '../../Shared/Components/TextInput';
import { ContentUploader } from '../../Shared/Components/Uploader/ContentUploader';
import { UploadContentType } from '../../Shared/Components/Uploader/Uploader.types';
import { Guard } from '../../Shared/Utils/Guard';
import { SectionTitle } from '../../Shared/Components/Layout';
import { Label } from '../../Shared/Components/Label';
import { useCoachVideo } from '../../Shared/Hooks/useCoachVideo';
import { FileInput } from '../../Shared/Components/FileInput';
import { InputGroup } from '../../Shared/Components/InputGroup';
import { Checkbox } from '../../Shared/Components/Checkbox';

export function CoachVideoEditScreen(): React.ReactElement {
    const { push } = useHistory();
    const [errors, setErrors] = React.useState<ValidationErrors>({});
    const { id, trainingId } = useParams<{ id?: string; trainingId: string }>();
    const [update, setUpdate] = useCoachVideo(trainingId, id);
    const [showSpinner, setSpinner] = React.useState<boolean>(false);

    const handleUpdated = (
        name: string,
        value: string | IFile | number | null | string[] | boolean
    ): void => {
        setUpdate((p) => ({
            ...p,
            [name]: value,
        }));
    };

    const isPayloadValid = (): boolean => {
        const validationErrors = new Guard()
            .againstMinimumCharacter(update.title, 2, 'title', 'Title is invalid.')
            .getResult();

        setErrors(validationErrors);

        return Object.keys(validationErrors).length === 0;
    };

    const handleSaveRequested = async (): Promise<void> => {
        if (!isPayloadValid()) return;

        try {
            setSpinner(true);
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/team-resources`,
                method: 'post',
                data: {
                    ...update,
                },
            });

            alert('Success!');
            push(`/trainings/${trainingId}/coach-videos`);
        } catch {
            alert('Failed!');
        } finally {
            setSpinner(false);
        }
    };

    return (
        <>
            {showSpinner && <Spinner />}
            <MainHeader
                renderLeft={() => <>{!update.id ? 'New Coach Video' : 'Edit Coach Video'}</>}
            />
            <Section
                header={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button text="Save" onClick={handleSaveRequested} />
                    </div>
                }
            >
                <SectionTitle>General information</SectionTitle>

                <InputGroup>
                    <Checkbox
                        name="isHowToCoachContent"
                        value={update.isHowToCoachContent || false}
                        label="How to Apeak Coach content"
                        onChange={handleUpdated}
                    />
                </InputGroup>

                <TextInput
                    name="title"
                    value={update.title}
                    label="Title"
                    onChange={handleUpdated}
                    errorMessage={errors.title}
                    required
                />

                <TextInput
                    name="description"
                    value={update.description}
                    label="Description"
                    onChange={handleUpdated}
                    hint="Detail information about the video"
                    errorMessage={errors.authorLink}
                />

                <SectionTitle>Video</SectionTitle>

                <TextInput
                    name="linkedVideoUrl"
                    value={update.linkedVideoUrl}
                    label="External video url"
                    onChange={handleUpdated}
                    hint="Provide URL to youtube. For example: https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                    errorMessage={errors.linkedVideoUrl}
                />

                <Label>Organic video</Label>
                <ContentUploader
                    containerName="videos"
                    contentType={UploadContentType.Video}
                    name="organicVideoUrl"
                    value={update?.organicVideoUrl}
                    onChange={handleUpdated}
                />

                <FileInput
                    name="videoThumbnail"
                    value={update.videoThumbnailUrl}
                    onChange={handleUpdated}
                    type="image"
                    defaultValue={update?.videoThumbnailUrl}
                    label="Video Thumbnail"
                    errorMessage={errors.videoThumbnailUrl}
                />
            </Section>
        </>
    );
}
