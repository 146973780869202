import styled, { css } from 'styled-components';
import { Color } from '../../Color';

export const Root = styled.section<{ isDangerZone?: boolean }>`
    border-radius: 20px;
    background-color: white;

    ${({ isDangerZone }) =>
        isDangerZone &&
        css`
            border: 2px solid rgb(215, 58, 73);
        `}
`;

export const Header = styled.div`
    padding: 20px 30px;
    border-bottom: 1px solid ${Color.DefaultBorder};
    width: 100%;
`;

export const Footer = styled.div`
    padding: 20px 30px;
    border-top: 1px solid ${Color.DefaultBorder};
    width: 100%;
`;

export const Heading = styled.div`
    background: #ffffff;
    line-height: 60px;
    font-size: 22px;
    font-weight: 600;
    color: #2a2c2e;
    padding-left: 30px;
    padding-right: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

export const Content = styled.div<{ hasHeading?: boolean; backgroundColor?: string }>`
    background-color: white;
    padding: 20px 30px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    ${({ hasHeading }) =>
        !hasHeading &&
        css`
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;
