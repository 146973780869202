import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { ITrainingContent } from '../../Models/ITrainingContent';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Spinner } from '../../Shared/Components/Spinner';
import { Table } from '../../Shared/Components/Table';
import { TextInput } from '../../Shared/Components/TextInput';
import { useTrainingContents } from '../../Shared/Hooks/useTrainingContents';

export function TrainingLibraryScreen(): React.ReactElement {
    const { trainingId } = useParams<{ trainingId: string }>();
    const [filter, setFilter] = React.useState<string>('');
    const [search, setSearch] = React.useState<string>('');
    const visualizations = useTrainingContents(trainingId);
    const { push, go } = useHistory();
    const [saving, setSaving] = React.useState<boolean>(false);
    const isLoading = visualizations === null;

    const isMatchingType = (type: string | undefined, v: ITrainingContent): boolean => {
        if (!type) return true;
        if (type.toLowerCase() === v.visualizationType.toLowerCase()) return true;

        return false;
    };

    const handleAddNewButtonClicked = (): void => {
        push(`/trainings/${trainingId}/library/new`);
    };

    const handleDeleteButtonClicked = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (r: any): Promise<void> => {
            if (
                window.confirm(
                    `This will permanently delete this content: "${r.name}", and is not reversible. Continue?`
                )
            ) {
                try {
                    setSaving(true);

                    await Http.request({
                        url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/contents/${r.id}`,
                        method: 'delete',
                    });

                    alert('Success!');
                } catch {
                    alert('Failed!');
                } finally {
                    setSaving(false);
                    go(0);
                }
            }
        },
        [go, trainingId]
    );

    const tableData = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        visualizations?.forEach((v) => {
            if (!isMatchingType(filter, v)) return;
            if (search && v.name.toLowerCase().indexOf(search.toLowerCase()) === -1) return;

            result.push({
                id: v.id,
                name: v.name,
                type: v.visualizationType,
                matchType: v.matchVisualizationType === 'NA' ? 'N/A' : v.matchVisualizationType,
                contentCount: v.visualizationContents.length,
                editLinkText: 'Edit',
                editLink: `/trainings/${trainingId}/library/${v.id}`,
                deleteButtonText: 'Delete',
                deleteCallback: handleDeleteButtonClicked,
            });
        });

        return result;
    }, [visualizations, filter, search, trainingId, handleDeleteButtonClicked]);

    return (
        <>
            {saving && <Spinner />}

            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>Library</PageTitle>

            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextInput
                            name="search"
                            value={search}
                            placeholder="Start typing to search..."
                            onChange={(name: string, newValue: string) => setSearch(newValue)}
                            noMargin
                        />
                        <Select
                            name="filter"
                            value={filter}
                            placeholder="All"
                            options={[
                                {
                                    key: 'Learn',
                                    value: 'Learn',
                                },
                                {
                                    key: 'Practice',
                                    value: 'Practice',
                                },
                                {
                                    key: 'Meditation',
                                    value: 'Meditation',
                                },
                                {
                                    key: 'Match',
                                    value: 'Match',
                                },
                                {
                                    key: 'Affirmation',
                                    value: 'Affirmation',
                                },
                            ]}
                            onChange={(name, newValue) => setFilter(newValue)}
                            noMargin
                        />
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Add new" onClick={handleAddNewButtonClicked} />
                        </div>
                    </div>
                }
            >
                <Table
                    isLoading={isLoading}
                    columns={[
                        { key: 'name', name: 'Name', style: { textAlign: 'left', maxWidth: 150 } },
                        { key: 'type', name: 'Type', style: { textAlign: 'left', maxWidth: 50 } },
                        {
                            key: 'matchType',
                            name: 'Match',
                            style: { textAlign: 'left', maxWidth: 50 },
                        },
                        {
                            key: 'contentCount',
                            name: 'Contents',
                            style: { textAlign: 'center', maxWidth: 50 },
                        },
                        {
                            key: 'edit',
                            name: '',
                            type: 'link',
                            linkKey: 'editLink',
                            linkText: 'editLinkText',
                            style: { textAlign: 'center', maxWidth: 50 },
                        },
                        {
                            key: 'delete',
                            name: '',
                            type: 'button',
                            buttonText: 'deleteButtonText',
                            buttonKey: 'deleteCallback',
                            style: { textAlign: 'center', maxWidth: 50 },
                        },
                    ]}
                    data={tableData}
                />
            </Section>
        </>
    );
}
