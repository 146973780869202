import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IFile } from '../../Models/IFile';
import { IVisualizationContentUpdateModel } from '../../Models/ITrainingContentUpdateModel';
import { Button } from '../../Shared/Components/Button';
import { FileInput } from '../../Shared/Components/FileInput';
import { Icon } from '../../Shared/Components/Icon';
import {
    PageTitle,
    PageTitleDescription,
    SectionTitle,
    SetTitle,
    SetTitleType,
} from '../../Shared/Components/Layout';
import { MultiSelect } from '../../Shared/Components/MultiSelect';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Spinner } from '../../Shared/Components/Spinner';
import { TagInput } from '../../Shared/Components/TagInput';
import { TextInput } from '../../Shared/Components/TextInput';
import { ContentUploader } from '../../Shared/Components/Uploader/ContentUploader';
import { UploadContentType } from '../../Shared/Components/Uploader/Uploader.types';
import { saveVisualization } from '../../Shared/Helpers/SaveVisualization';
import { useTraining } from '../../Shared/Hooks/useTraining';
import { useTrainingContent } from '../../Shared/Hooks/useTrainingContent';
import { VisualizationContentType } from './TrainingLibrary.types';

export function TrainingLibraryEditScreen(): React.ReactElement {
    const { id, trainingId } = useParams<{ id?: string; trainingId: string }>();
    const { push } = useHistory();
    const [visualization, update, setUpdate] = useTrainingContent(trainingId, id);
    const training = useTraining(trainingId);
    const [isSaving, setSaving] = React.useState<boolean>(false);

    const mentalSKillOptions = React.useMemo(() => {
        if (!training) return [];

        return training?.mentalSkills
            .filter((p) => p.type !== 'Situation')
            .map((t) => ({
                key: t.id,
                value: t.name,
            }));
    }, [training]);

    const competitiveSkillOptions = React.useMemo(() => {
        if (!training) return [];

        return training?.competitiveSkills.map((t) => ({
            key: t.id,
            value: `${t.type} | ${t.name}`,
        }));
    }, [training]);

    const situationOptions = React.useMemo(() => {
        if (!training) return [];

        return training?.mentalSkills
            .filter((p) => p.type === 'Situation')
            .map((t) => ({
                key: t.id,
                value: t.name,
            }));
    }, [training]);

    const handleUpdated = (
        name: string,
        value: string | number | string[] | IFile | null
    ): void => {
        setUpdate((p) => {
            let matchVisualizationType = p.matchVisualizationType;
            let subscriptionLevel = p.subscriptionLevel;

            if (name === 'competitiveSkillId') {
                const competitiveSkill = training?.competitiveSkills.find((p) => p.id === value);
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                matchVisualizationType = competitiveSkill?.type;
            }

            if (name === 'subscriptionLevel') {
                subscriptionLevel = parseInt(value as string);
            }

            return {
                ...p,
                [name]: value,
                matchVisualizationType,
                subscriptionLevel,
            };
        });
    };

    const handleBackPressed = () => {
        push(`/trainings/${trainingId}/library`);
    };

    const getDefaultContent = (
        type: VisualizationContentType
    ): IVisualizationContentUpdateModel => {
        return {
            contentType: type,
            file: null,
            url: null,
            length: 0,
        };
    };

    const updateContent = (type: VisualizationContentType, url: string, length: number): void => {
        const contentIndex = update?.visualizationContents.findIndex((p) => p.contentType === type);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (contentIndex > -1) {
            if (url === null) {
                update?.visualizationContents.splice(contentIndex, 1);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                update.visualizationContents[contentIndex].url = url;
                update.visualizationContents[contentIndex].length = length;
            }
        } else {
            update?.visualizationContents.push({
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                contentType: type,
                url,
                length,
            });
        }

        setUpdate({
            ...update,
        });
    };
    const updateContentCasual = (
        type: VisualizationContentType,
        url: string,
        length: number
    ): void => {
        const contentIndex = update?.visualizationContents.findIndex((p) => p.contentType === type);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (contentIndex > -1) {
            if (url === null) {
                update?.visualizationContents.splice(contentIndex, 1);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                update.visualizationContents[contentIndex].url = url;
                update.visualizationContents[contentIndex].length = length;
            }
        } else {
            update?.visualizationContents.push({
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                contentType: type,
                url,
                length,
            });
        }

        setUpdate({
            ...update,
        });
    };
    const updateContentFocused = (
        type: VisualizationContentType,
        url: string,
        length: number
    ): void => {
        const contentIndex = update?.focusedVisualizationContents.findIndex(
            (p) => p.contentType === type
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (contentIndex > -1) {
            if (url === null) {
                update?.focusedVisualizationContents.splice(contentIndex, 1);
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                update.focusedVisualizationContents[contentIndex].url = url;
                update.focusedVisualizationContents[contentIndex].length = length;
            }
        } else {
            update?.focusedVisualizationContents.push({
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                contentType: type,
                url,
                length,
            });
        }

        setUpdate({
            ...update,
        });
    };

    const handleSave = (): void => {
        setSaving(true);
        console.log('update', update);
        saveVisualization(update)
            .then(() => {
                alert('Success!');
                setSaving(false);
                push(`/trainings/${trainingId}/library`);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const defaultContentCasual: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.Default
        ) || getDefaultContent(VisualizationContentType.Default);
    const withInspiringMusicContentCasual: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.WithInspiringMusic
        ) || getDefaultContent(VisualizationContentType.WithInspiringMusic);
    const withBrainWaveContentCasual: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.WithBrainWave
        ) || getDefaultContent(VisualizationContentType.WithBrainWave);
    const defaultContentFocused: IVisualizationContentUpdateModel =
        update?.focusedVisualizationContents.find(
            (p) => p.contentType === VisualizationContentType.Default
        ) || getDefaultContent(VisualizationContentType.Default);
    const withInspiringMusicContentFocused: IVisualizationContentUpdateModel =
        update?.focusedVisualizationContents.find(
            (p) => p.contentType === VisualizationContentType.WithInspiringMusic
        ) || getDefaultContent(VisualizationContentType.WithInspiringMusic);
    const withBrainWaveContentFocused: IVisualizationContentUpdateModel =
        update?.focusedVisualizationContents.find(
            (p) => p.contentType === VisualizationContentType.WithBrainWave
        ) || getDefaultContent(VisualizationContentType.WithBrainWave);

    const maleAudioContent: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.DefaultMaleAudio
        ) || getDefaultContent(VisualizationContentType.DefaultMaleAudio);
    const femaleAudioContent: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.DefaultFemaleAudio
        ) || getDefaultContent(VisualizationContentType.DefaultFemaleAudio);
    const shortMaleAudioContent: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.ShortMaleAudio
        ) || getDefaultContent(VisualizationContentType.ShortMaleAudio);
    const shortFemaleAudioContent: IVisualizationContentUpdateModel =
        update?.visualizationContents.find(
            (p) => p.contentType === VisualizationContentType.ShortFemaleAudio
        ) || getDefaultContent(VisualizationContentType.ShortFemaleAudio);
    console.log('visualization', visualization);
    return (
        <>
            {isSaving && <Spinner />}

            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>
                Library <Icon name="chevron-right" size={24} />{' '}
                {id ? visualization?.name : 'New training content'}
            </PageTitle>

            <Section
                header={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button text="Cancel" onClick={handleBackPressed} secondary />
                        <Button text="Save" onClick={handleSave} />
                    </div>
                }
                footer={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button text="Cancel" onClick={handleBackPressed} secondary />
                        <Button text="Save" onClick={handleSave} />
                    </div>
                }
            >
                <SectionTitle>General information</SectionTitle>

                {update.visualizationType === 'BookSummary' && (
                    <TextInput
                        name="bookAuthor"
                        value={update.bookAuthor || ''}
                        label="Book author"
                        onChange={handleUpdated}
                    />
                )}

                <Select
                    label="Author"
                    name="visualizationAuthor"
                    value={update.visualizationAuthor}
                    options={[
                        { key: 'Will Johnston', value: 'Will Johnston' },
                        { key: 'Dawn Wynne', value: 'Dawn Wynne' },
                        { key: 'Paul Schmidt', value: 'Paul Schmidt' },
                        { key: 'Jim Loehr', value: 'Jim Loehr' },
                    ]}
                    onChange={handleUpdated}
                />
                <Select
                    label="Author Image"
                    name="authorImage"
                    value={update.authorImage}
                    options={[
                        {
                            key:
                                'https://prodapeak.blob.core.windows.net/author-images/202309_superiormind_Will_Johnston.png',
                            value: 'Picture of Will',
                        },
                        {
                            key:
                                'https://prodapeak.blob.core.windows.net/author-images/202309_superiormind_Dawn_Wynne.png',
                            value: 'Picture of Dawn',
                        },
                        {
                            key:
                                'https://prodapeak.blob.core.windows.net/author-images/202309_superiormind_Paul_Schmidt.png',
                            value: 'Picture of Paul',
                        },
                        {
                            key:
                                'https://prodapeak.blob.core.windows.net/author-images/202309_superiormind_Jim_Loehr.png',
                            value: 'Picture of Jim',
                        },
                    ]}
                    onChange={handleUpdated}
                />

                <img
                    style={{ width: 100, height: 100, borderRadius: 100 }}
                    src={`${update.authorImage}`}
                    alt="Author"
                ></img>
                <p style={{ marginBottom: 30 }}>
                    PS: Image may take a second to update due to high resolution
                </p>
                <Select
                    label="Type"
                    name="visualizationType"
                    value={update.visualizationType}
                    options={[
                        { key: 'Learn', value: 'Learn' },
                        { key: 'Practice', value: 'Practice' },
                        { key: 'Match', value: 'Match' },
                        { key: 'Meditation', value: 'Meditation' },
                        { key: 'Affirmation', value: 'Affirmation' },
                        { key: 'BookSummary', value: 'Book Summary' },
                    ]}
                    onChange={handleUpdated}
                />

                <Select
                    label="Subscription level"
                    name="subscriptionLevel"
                    value={(update.subscriptionLevel || 0).toString()}
                    options={[
                        { value: 'Free', key: '0' },
                        { value: 'Premium', key: '1' },
                        { value: 'Superior', key: '2' },
                    ]}
                    onChange={handleUpdated}
                    hint="Select Free for all users and Basic for active users. More options to be added as more tiers are added."
                />

                <TextInput name="name" value={update.name} label="Name" onChange={handleUpdated} />

                <TextInput
                    name="description"
                    value={update.description}
                    label="Description"
                    onChange={handleUpdated}
                    multiline
                />

                <FileInput
                    name="backgroundImage"
                    value={update.backgroundImage}
                    onChange={handleUpdated}
                    type="image"
                    defaultValue={visualization?.backgroundImageUrl}
                    label="Background image"
                />

                <SectionTitle>Associations</SectionTitle>

                {(update.visualizationType === 'Practice' ||
                    update.visualizationType === 'Meditation' ||
                    update.visualizationType === 'Affirmation' ||
                    update.visualizationType === 'Learn') && (
                    <Select
                        name="mentalSkillId"
                        value={update.mentalSkillId}
                        label="Mental skill"
                        options={mentalSKillOptions}
                        onChange={handleUpdated}
                    />
                )}

                {update.visualizationType === 'Match' && (
                    <Select
                        name="competitiveSkillId"
                        value={update.competitiveSkillId}
                        label="Competitive skill"
                        options={competitiveSkillOptions}
                        onChange={handleUpdated}
                    />
                )}

                {(update.visualizationType === 'Practice' ||
                    update.visualizationType === 'Match' ||
                    update.visualizationType === 'Affirmation') && (
                    <MultiSelect
                        name="situations"
                        label="Situations"
                        value={update.situations}
                        options={situationOptions}
                        onChange={handleUpdated}
                    />
                )}

                {(update.visualizationType === 'Practice' ||
                    update.visualizationType === 'Match' ||
                    update.visualizationType === 'Affirmation' ||
                    update.visualizationType === 'BookSummary') && (
                    <TagInput
                        name="tags"
                        value={update.tags}
                        label="Tags"
                        onChange={handleUpdated}
                    />
                )}

                <SectionTitle>Contents</SectionTitle>
                <>
                    <SetTitleType>Casual</SetTitleType>
                    <SetTitle>No music</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.Default}
                        value={defaultContentCasual.url}
                        onChange={updateContentCasual}
                    />

                    <SetTitle>With inspiring music</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.WithInspiringMusic}
                        value={withInspiringMusicContentCasual.url}
                        onChange={updateContentCasual}
                    />

                    <SetTitle>With brain wave</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.WithBrainWave}
                        value={withBrainWaveContentCasual.url}
                        onChange={updateContentCasual}
                    />
                </>
                <>
                    <SetTitleType>Focused</SetTitleType>
                    <SetTitle>No music</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.Default}
                        value={defaultContentFocused.url}
                        onChange={updateContentFocused}
                    />

                    <SetTitle>With inspiring music</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.WithInspiringMusic}
                        value={withInspiringMusicContentFocused.url}
                        onChange={updateContentFocused}
                    />

                    <SetTitle>With brain wave</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.WithBrainWave}
                        value={withBrainWaveContentFocused.url}
                        onChange={updateContentFocused}
                    />
                </>

                <SectionTitle>Deprecated Contents</SectionTitle>
                <p style={{ marginBottom: 40 }}>
                    <b>DO NOT USE FOR SUPERIORMIND.</b>
                    <br />
                    <br />
                    <b>
                        DO NOT REMOVE THE BELOW CONTENTS UNTIL THE APEAK TENNIS UI IS FULLY CHANGED
                        AND FOR BACKWARD COMPATIBILITY.
                    </b>
                    <br />
                    The following content types are deprecated and will be removed once
                    SuperiorTennis is released.
                </p>

                <>
                    <SetTitle>Male audio</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.DefaultMaleAudio}
                        value={maleAudioContent.url}
                        onChange={updateContent}
                    />

                    <SetTitle>Female audio</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.DefaultFemaleAudio}
                        value={femaleAudioContent.url}
                        onChange={updateContent}
                    />

                    <SetTitle>Male audio - short</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.ShortMaleAudio}
                        value={shortMaleAudioContent.url}
                        onChange={updateContent}
                    />

                    <SetTitle>Female audio - short</SetTitle>
                    <ContentUploader
                        contentType={UploadContentType.Audio}
                        name={VisualizationContentType.ShortFemaleAudio}
                        value={shortFemaleAudioContent.url}
                        onChange={updateContent}
                    />
                </>
            </Section>
        </>
    );
}
