import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export const Root = styled.div<{ marginBottom?: number }>`
    ${({ marginBottom }): false | FlattenSimpleInterpolation =>
        !!marginBottom &&
        css`
            margin-bottom: ${marginBottom}px;
        `}
`;

export const Label = styled.label`
    display: block;
    line-height: 17px;
    margin-bottom: 12px;
`;

export const Input = styled.input`
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 600;
    display: block;
    width: 100%;
    height: 52px;
    padding: 16px;
`;
