export function timeSince(date: Date | null): string {
    if (date === null) return '--';

    const myDate = new Date(date);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const seconds = Math.floor((new Date() - myDate) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + ' yrs ago';
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ' mos ago';
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ` d${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + ` hr${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + ` min${Math.floor(interval) > 1 ? 's' : ''} ago`;
    }
    return Math.floor(seconds) + ` sec${Math.floor(seconds) > 1 ? 's' : ''} ago`;
}
