import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export const Row = styled.div<{ noMargin?: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: -16px;
    margin-right: -16px;

    ${({ noMargin }): FlattenSimpleInterpolation | false =>
        !noMargin &&
        css`
            margin-bottom: 32px;
        `}
`;

export const Col = styled.div<{ size: number }>`
    padding-left: 16px;
    padding-right: 16px;

    ${({ size }) => css`
        flex: ${size};
    `}
`;
