import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './App.routes';
import { AppContextProvider } from './Shared/Components/AppContextProvider';

export function App(): React.ReactElement {
    return (
        <AppContextProvider>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </AppContextProvider>
    );
}
