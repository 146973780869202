import * as React from 'react';
import { getTick } from '../../../Models/TelemetryDuration';
import * as Styled from './Analytics.styled';
import { AnalyticsClient } from '../../Clients/AnalyticsClient';
import { Card } from '../Card';
import { Line } from 'react-chartjs-2';
import { timeSince } from './TimeSince';

const getQuery = (time: string, trainingId: string): string => {
    const tick = getTick(time);

    return `
                let intervals =
                range timestamp from ago(${time}) to now() step ${tick}
                | extend c = 0
                | summarize ['sum_c'] = sum(c) by bin(timestamp, ${tick}) 
                | project-away sum_c 
                | order by timestamp asc;
                let g =
                customEvents
                | where timestamp >= ago(${time})
                | where name == 'FreeTrialStarted'
                | where customDimensions.trainingId == '${trainingId}'
                | summarize ['count_g'] = sum(itemCount) by bin(timestamp, ${tick});
                let f =
                customEvents
                | where timestamp >= ago(${time})
                | where name == 'FreeTrialExpired'
                | where customDimensions.trainingId == '${trainingId}'
                | summarize ['count_f'] = sum(itemCount) by bin(timestamp, ${tick});
                intervals
                | join kind= leftouter (g) on timestamp
                | join kind= leftouter (f) on timestamp
                | project timestamp, count_g, count_f
                | order by timestamp asc;
   `;
};

export function FreeTrialTrend(props: { time: string; trainingId: string }): React.ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [data, setData] = React.useState<any[][]>([]);

    React.useEffect(() => {
        setData([]);
        AnalyticsClient.query(getQuery(props.time, props.trainingId)).then(setData);
    }, [props.time, props.trainingId]);

    const labels = data.map((p) => {
        return timeSince(p[0]);
    });

    const newDataSet = data.map((p) => p[1] || 0);
    let totalNew = 0;
    newDataSet.forEach((p) => (totalNew += p));

    const expiredDataSet = data.map((p) => p[2] || 0);
    let totalExpired = 0;
    expiredDataSet.forEach((p) => (totalExpired += p));

    return (
        <Card>
            <div style={{ height: 284 }}>
                <Styled.GraphRoot height={130}>
                    {data !== [] && (
                        <Line
                            data={{
                                labels: labels,
                                datasets: [
                                    {
                                        label: 'New',
                                        data: newDataSet,
                                        fill: true,
                                        borderColor: 'rgba(54, 162, 235, 1)',
                                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                                        pointRadius: 4,
                                        borderWidth: 2,
                                        pointBackgroundColor: 'white',
                                    },
                                    {
                                        label: 'Expired',
                                        data: expiredDataSet,
                                        fill: true,
                                        borderColor: 'rgba(255, 99, 132, 1)',
                                        backgroundColor: 'rgba(255, 99, 132, 1)',
                                        pointRadius: 4,
                                        borderWidth: 2,
                                        pointBackgroundColor: 'white',
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    y: {
                                        display: true,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            stepSize: 1,
                                            font: {
                                                family: 'DM Sans',
                                                size: 10,
                                            },
                                        },
                                    },
                                    x: {
                                        display: true,
                                        grid: {
                                            display: false,
                                        },
                                        ticks: {
                                            font: {
                                                family: 'DM Sans',
                                                size: 10,
                                            },
                                            maxRotation: 0,
                                            autoSkipPadding: 50,
                                        },
                                    },
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                            }}
                        />
                    )}
                </Styled.GraphRoot>

                <Styled.Heading bottom="0px">
                    <Styled.Title>Free trial</Styled.Title>

                    <Styled.Figures>
                        <Styled.FigureRoot>
                            <Styled.Figure color="rgba(54, 162, 235, 1)">{totalNew}</Styled.Figure>
                            <Styled.FigureDescirption>New</Styled.FigureDescirption>
                        </Styled.FigureRoot>
                        <Styled.FigureRoot>
                            <Styled.Figure color="rgba(255, 99, 132, 1)">
                                {totalExpired}
                            </Styled.Figure>
                            <Styled.FigureDescirption>Expired</Styled.FigureDescirption>
                        </Styled.FigureRoot>
                    </Styled.Figures>
                </Styled.Heading>
            </div>
        </Card>
    );
}
