import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Auth } from '../../../Clients/Auth';
import { Icon } from '../../Icon';
import * as Styled from './Nav.styled';

function Nav(props: RouteComponentProps): React.ReactElement {
    const { match } = props;
    const [isSales, setSales] = React.useState<boolean>(false);

    React.useEffect(() => {
        Auth.isSales().then(setSales);
    }, []);

    return (
        <Styled.Root>
            {!isSales && (
                <>
                    <Styled.MenuHeading>Dashboards</Styled.MenuHeading>
                    <Styled.MenuList>
                        <Styled.MenuItem>
                            <Styled.MenuItemLink to={match.url} activeClassName="active" exact>
                                <Icon name="graph-up" size={20} marginRight={15} />
                                Business
                            </Styled.MenuItemLink>
                        </Styled.MenuItem>
                    </Styled.MenuList>
                </>
            )}

            <Styled.MenuHeading>App Management</Styled.MenuHeading>
            <Styled.MenuList>
                {!isSales && (
                    <>
                        <Styled.MenuItemLink to={`${match.url}/find-user`} activeClassName="active">
                            <Icon name="person" size={20} marginRight={15} />
                            User
                        </Styled.MenuItemLink>
                        <Styled.MenuItemLink to={`${match.url}/users`} activeClassName="active">
                            <Icon name="person" size={20} marginRight={15} />
                            <span style={{ textDecoration: 'line-through' }}>Users</span>
                        </Styled.MenuItemLink>
                    </>
                )}
                <Styled.MenuItemLink to={`${match.url}/coaches`} activeClassName="active">
                    <Icon name="people" size={20} marginRight={15} />
                    Apeak Team
                </Styled.MenuItemLink>
            </Styled.MenuList>

            {!isSales && (
                <>
                    <Styled.MenuHeading>Content Management</Styled.MenuHeading>
                    <Styled.MenuList>
                        <Styled.MenuItemLink
                            to={`${match.url}/mentalskills`}
                            activeClassName="active"
                        >
                            <Icon name="lightbulb" size={20} marginRight={15} />
                            Mental skills
                        </Styled.MenuItemLink>
                        <Styled.MenuItemLink
                            to={`${match.url}/competitiveskills`}
                            activeClassName="active"
                        >
                            <Icon name="trophy" size={20} marginRight={15} />
                            Competitive skills
                        </Styled.MenuItemLink>
                        <Styled.MenuItemLink to={`${match.url}/library`} activeClassName="active">
                            <Icon name="headphones" size={20} marginRight={15} />
                            Library
                        </Styled.MenuItemLink>
                        <Styled.MenuItemLink to={`${match.url}/resources`} activeClassName="active">
                            <Icon name="file-play" size={20} marginRight={15} />
                            Resources
                        </Styled.MenuItemLink>
                        <Styled.MenuItemLink
                            to={`${match.url}/coach-videos`}
                            activeClassName="active"
                        >
                            <Icon name="camera-video" size={20} marginRight={15} />
                            Coach Videos
                        </Styled.MenuItemLink>
                    </Styled.MenuList>

                    <Styled.MenuHeading>Testing Tools</Styled.MenuHeading>
                    <Styled.MenuList>
                        <Styled.MenuItemLink
                            to={`${match.url}/tests/recommendations`}
                            activeClassName="active"
                        >
                            <Icon name="person" size={20} marginRight={15} />
                            Recommendations
                        </Styled.MenuItemLink>
                    </Styled.MenuList>
                </>
            )}
        </Styled.Root>
    );
}

const connected = withRouter(Nav);
export { connected as Nav };
