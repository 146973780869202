import styled from 'styled-components';

export const Root = styled.div`
    height: 48px;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 30px;
`;

export const Left = styled.div`
    font-size: 1.875rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    line-height: 1.235;
`;

export const Right = styled.div``;
