class CacheClient {
    public set(cacheKey: string, data: {}): void {
        localStorage.setItem(cacheKey, JSON.stringify(data));
    }

    public get<T>(cacheKey: string): T | null {
        const cached = localStorage.getItem(cacheKey);
        if (!cached) return null;

        return JSON.parse(cached) as T;
    }

    public remove(cacheKey: string): void {
        localStorage.removeItem(cacheKey);
    }
}

const cacheClient = new CacheClient();
export { cacheClient as CacheClient };
