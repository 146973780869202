import * as React from 'react';
import { Button } from '../../Shared/Components/Button';
import { Icon } from '../../Shared/Components/Icon';
import {
    PageTitle,
    PageTitleDescription,
    SectionTitle,
    Row,
    Col,
} from '../../Shared/Components/Layout';
import { TextInput } from '../../Shared/Components/TextInput';
import { useCoachDetail } from '../../Shared/Hooks/useCoachDetail';
import * as Styled from './CoachDetailScreen.styled';
import { useHistory, useParams } from 'react-router-dom';
import { Section } from '../../Shared/Components/Section';
import { Http } from '../../Shared/Clients/Http';
import { AppConfig } from '../../App.config';
import { getDateText } from '../UsersScreen';

export function CoachDetailScreen(): React.ReactElement {
    const { trainingId, userId } = useParams<{
        trainingId: string;
        email: string;
        userId: string;
    }>();
    const coachDetail = useCoachDetail(userId);
    const history = useHistory();
    const [partnershipCode, setPartnershipCode] = React.useState<string>(
        coachDetail?.partnershipCode ?? ''
    );

    const handleBackButtonPressed = (): void => {
        history.push(`/trainings/${trainingId}/coaches`);
    };

    const handleApproveButtonPressed = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/coaches/${userId}/approve`,
                method: 'put',
            });
            window.location.reload();
        } catch {
            alert('Failed!');
        }
    };

    const handleRejectButtonPressed = (): void => {
        // history.push(`/trainings/${trainingId}/coaches`);
        // try {
        //     await Http.request({
        //         url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${trainee?.userId}`,
        //         method: 'delete',
        //     });
        //     alert('Success!');
        //     history.go(0);
        // } catch {
        //     alert('Failed!');
        // }
    };

    const handleAddPartnershipCodeButtonPressed = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/coaches/${userId}/partnership/${partnershipCode}`,
                method: 'put',
            });
            window.location.reload();
        } catch {
            alert('Failed!');
        }
    };

    const handleDeleteButtonPressed = async () => {
        history.push(`/trainings/${trainingId}/coaches`);
        if (
            window.confirm(
                `Are you going to remove ${coachDetail?.coachInfo?.firstName ?? ''} ${
                    coachDetail?.coachInfo?.lastName ?? ''
                }`
            )
        ) {
            try {
                await Http.request({
                    url: `${AppConfig.endpoints.admin.url}/coaches/${coachDetail?.userId}`,
                    method: 'delete',
                });
                alert('Success!');
                history.go(0);
            } catch {
                alert('Failed!');
            }
        }
    };
    console.log(coachDetail);
    return (
        <>
            <PageTitleDescription>App Management</PageTitleDescription>
            <PageTitle>
                Coaches <Icon name="chevron-right" size={24} /> {coachDetail?.coachInfo?.firstName}{' '}
                {coachDetail?.coachInfo?.lastName} ({coachDetail?.coachInfo?.email})
            </PageTitle>

            <Section
                header={
                    <>
                        {coachDetail && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <div>
                                    <Button
                                        text="Back"
                                        onClick={handleBackButtonPressed}
                                        secondary
                                    />
                                    {!coachDetail.isVerified && (
                                        <Button
                                            text="Approve"
                                            onClick={handleApproveButtonPressed}
                                            secondary
                                        />
                                    )}
                                    {coachDetail.isVerified && (
                                        <Button
                                            text="Reject"
                                            onClick={handleRejectButtonPressed}
                                            reject
                                        />
                                    )}

                                    <Button
                                        text="Delete"
                                        onClick={handleDeleteButtonPressed}
                                        danger
                                    />
                                    {coachDetail.isVerified ? (
                                        <Styled.ApproveDateText>
                                            {`Approved in ${getDateText(
                                                new Date(coachDetail.attestation.verifiedDate)
                                            )}`}
                                        </Styled.ApproveDateText>
                                    ) : (
                                        <Styled.ApproveDateText>
                                            {`Requested in ${getDateText(
                                                new Date(coachDetail.createdDate)
                                            )}`}
                                        </Styled.ApproveDateText>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                }
                footer={
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <TextInput
                                name="partnershipCode"
                                placeholder="Add partnership code"
                                value={partnershipCode}
                                onChange={(_, newValue) => setPartnershipCode(newValue.toString())}
                            />
                            <Button text="Add" onClick={handleAddPartnershipCodeButtonPressed} />
                        </div>
                    </>
                }
            >
                <Row>
                    <Col size={1}>
                        <Section backgroundColor="rgba(0, 0, 0, 0.02)">
                            {coachDetail && (
                                <>
                                    <SectionTitle>Basic information</SectionTitle>

                                    <Styled.DataGroup>
                                        <Styled.Label>User Id</Styled.Label>
                                        {coachDetail.userId}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Trainning Id</Styled.Label>
                                        {coachDetail.trainingId}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Name</Styled.Label>{' '}
                                        {coachDetail.coachInfo?.firstName}{' '}
                                        {coachDetail.coachInfo?.lastName}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Email</Styled.Label>{' '}
                                        {coachDetail.coachInfo?.email}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>User type</Styled.Label>{' '}
                                        {coachDetail.coachInfo?.userType}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Status</Styled.Label>{' '}
                                        {coachDetail.isVerified ? 'Approved' : 'Pending'}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Partnership Code</Styled.Label>{' '}
                                        {coachDetail.partnershipCode ?? 'Not set'}
                                    </Styled.DataGroup>
                                </>
                            )}
                        </Section>
                    </Col>
                    <Col size={2}>
                        <Section>
                            <SectionTitle>Detailed information</SectionTitle>
                            {coachDetail && (
                                <>
                                    <Styled.DataGroup>
                                        <Styled.Label>Phone Number</Styled.Label>
                                        {coachDetail.coachInfo?.phoneNumber}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Self Employed</Styled.Label>
                                        {coachDetail.coachInfo?.isSelfEmployed ? 'Yes' : 'No'}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Number of Trainee Assignment</Styled.Label>
                                        {coachDetail.traineeAssignments.length}
                                    </Styled.DataGroup>
                                </>
                            )}
                        </Section>
                    </Col>
                </Row>
            </Section>
        </>
    );
}
