import styled from 'styled-components';

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
`;

export const Root = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const CloseRoot = styled.div`
    margin-bottom: 20px;
`;

export const PreviewRoot = styled.div`
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;

    &.desktop-view {
        width: 840px;
        height: 800px;
        padding: 40px;

        h1 {
            font-size: 40px;
            line-height: 48px;
            color: #333;
            margin-bottom: 20px;
            margin-top: 0;
        }

        h2 {
        }

        h3 {
        }

        ul,
        ol {
            padding-left: 40px;
            margin-bottom: 50px;
        }

        p {
            font-size: 20px;
            line-height: 35px;
            color: #4f4f4f;
            margin-bottom: 50px;
        }

        li {
            font-size: 20px;
            line-height: 35px;
            color: #4f4f4f;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &.mobile-view {
        width: 343px;
        height: 800px;
        padding: 16px;

        h1 {
            font-size: 20px;
            line-height: 25px;
            color: #333;
            margin-bottom: 25px;
            margin-top: 0;
        }

        h2 {
            font-size: 18px;
            line-height: 20px;
            color: #333333;
            margin-bottom: 25px;
            margin-top: 0;
        }

        h3 {
            font-size: 15px;
            line-height: 18px;
            color: #333333;
            margin-bottom: 25px;
            margin-top: 0;
        }

        ul,
        ol {
            padding-left: 20px;
            margin-bottom: 25px;
        }

        p {
            font-size: 17px;
            line-height: 22px;
            color: #4f4f4f;
            margin-bottom: 25px;
        }

        li {
            font-size: 17px;
            line-height: 22px;
            color: #4f4f4f;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
`;
