import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../Shared/Components/Button';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Table } from '../../Shared/Components/Table';
import { TextInput } from '../../Shared/Components/TextInput';
import { useUsers } from '../../Shared/Hooks/useUsers';

export function UsersScreen(): React.ReactElement {
    const { trainingId } = useParams<{ trainingId: string }>();
    const [users] = useUsers(trainingId);
    const [filter, setFilter] = React.useState<string>('');
    const [search, setSearch] = React.useState<string>('');

    const tableData = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        users?.forEach((v) => {
            if (filter && filter !== v.subscriptionStatus) return;

            const name = `${v.firstName} ${v.lastName}`;
            const email = v.email?.toLowerCase();
            if (search && name.indexOf(search) === -1 && email.indexOf(search) === -1) return;

            result.push({
                name,
                email,
                login: v.authProvider,
                subscriptionStatus: v.subscriptionStatus,
                store: v.store,
                detailLinkText: 'Details',
                detailLink: `/trainings/${trainingId}/trainees/${v.userId}/${v.email}`,
            });
        });

        result.sort((a, b) => (a.subscriptionStatus < b.subscriptionStatus ? -1 : 1));

        return result;
    }, [trainingId, users, filter, search]);

    const exportToCsv = () => {
        const filename = `${new Date().toDateString()}.csv`;
        const rows: string[][] = users!
            .filter((p) => (filter ? filter === p.subscriptionStatus : true))
            .map((p) => {
                return [
                    p.firstName,
                    p.lastName,
                    p.email,
                    p.subscriptionStatus,
                    p.subEndDate,
                    p.isRenewing,
                    p.isFreeAccount,
                    p.promotionCode,
                ];
            });

        const processRow = (row: string[]) => {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                const innerValue = row[j];
                let result = (innerValue || '').replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
                if (j > 0) finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile =
            'FirstName,LastName,Email,SubscriptionStatus,SubscriptionEndOrRenewalDate,IsRenewing,IsFreeAccount,PromotionCode\n';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        // @ts-ignore
        if (navigator.msSaveBlob) {
            // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    return (
        <>
            <PageTitleDescription>App Management</PageTitleDescription>
            <PageTitle>Users (Deprecated)</PageTitle>

            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextInput
                            name="search"
                            value={search}
                            placeholder="Start typing to search..."
                            onChange={(name: string, newValue: string) => setSearch(newValue)}
                            noMargin
                        />
                        <Select
                            name="filter"
                            value={filter}
                            placeholder="All"
                            options={[
                                {
                                    key: 'ACTIVE',
                                    value: 'Active',
                                },
                                {
                                    key: 'EXPIRED',
                                    value: 'Expired',
                                },
                                {
                                    key: 'FREETRIAL',
                                    value: 'Free trial',
                                },
                                {
                                    key: 'N/A',
                                    value: 'Never Subscribed',
                                },
                            ]}
                            onChange={(name, newValue) => setFilter(newValue)}
                            noMargin
                        />

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Export" onClick={exportToCsv} />
                        </div>
                    </div>
                }
            >
                <Table
                    isLoading={users === null}
                    columns={[
                        {
                            key: 'name',
                            name: 'Name',
                            style: { textAlign: 'left', maxWidth: 150 },
                        },
                        {
                            key: 'email',
                            name: 'Email',
                            style: { textAlign: 'left', maxWidth: 200 },
                        },
                        {
                            key: 'login',
                            name: 'Login',
                            style: { textAlign: 'left', maxWidth: 70 },
                        },
                        {
                            key: 'store',
                            name: 'Store',
                            style: { textAlign: 'left', maxWidth: 60 },
                        },
                        {
                            key: 'subscriptionStatus',
                            name: 'Subscription',
                            style: { textAlign: 'center', maxWidth: 70 },
                        },
                        {
                            key: 'details',
                            name: 'Actions',
                            type: 'link',
                            linkKey: 'detailLink',
                            linkText: 'detailLinkText',
                            style: { textAlign: 'center', maxWidth: 70 },
                        },
                    ]}
                    data={tableData}
                />
            </Section>
        </>
    );
}
