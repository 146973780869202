import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IFile } from '../../Models/IFile';
import { IMentalSkillUpdateModel } from '../../Models/ITrainingUpdateModel';
import { Button } from '../../Shared/Components/Button';
import { FileInput } from '../../Shared/Components/FileInput';
import { Icon } from '../../Shared/Components/Icon';
import { PageTitle, PageTitleDescription, SectionTitle } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Spinner } from '../../Shared/Components/Spinner';
import { TextInput } from '../../Shared/Components/TextInput';
import { saveTraining } from '../../Shared/Helpers/SaveTraining';
import { useMentalSkill } from '../../Shared/Hooks/useMentalSkill';

export function MentalSkillEditScreen(): React.ReactElement {
    const { trainingId, id } = useParams<{ trainingId: string; id?: string }>();
    const [training, mentalSkill, update, setUpdate] = useMentalSkill(trainingId, id);
    const [isSaving, setSaving] = React.useState<boolean>(false);
    const [choiceOne, setChoiceOne] = React.useState<string>('');
    const [choiceTwo, setChoiceTwo] = React.useState<string>('');
    const { push } = useHistory();

    React.useEffect(() => {
        if (mentalSkill && mentalSkill.reinforcementType === 'Multiple') {
            const keys = Object.keys(mentalSkill?.reinforcementChoices);
            if (keys.length > 0) setChoiceOne(keys[0]);
            if (keys.length > 1) setChoiceTwo(keys[1]);
        }
    }, [mentalSkill]);

    const handleUpdated = (name: string, value: IFile | null | string | number): void => {
        setUpdate((p) => ({
            ...p,
            [name]: value,
        }));
    };

    const handleReinforcementTypeChanged = (name: string, value: string): void => {
        if (value === 'Single') {
            setUpdate(
                (p): IMentalSkillUpdateModel => ({
                    ...p,
                    [name]: value,
                    reinforcementTitles: {
                        default: '',
                    },
                    reinforcementChoices: {
                        default: '',
                    },
                })
            );
        } else {
            setUpdate(
                (p): IMentalSkillUpdateModel => ({
                    ...p,
                    [name]: value,
                    reinforcementTitles: {},
                    reinforcementChoices: {},
                })
            );
        }
    };

    const handleSingleChoiceUpdated = (name: string, value: string): void => {
        setUpdate(
            (p): IMentalSkillUpdateModel => ({
                ...p,
                [name]: {
                    default: value,
                },
            })
        );
    };

    const handleChoiceOneUpdated = (name: string, value: string): void => {
        setUpdate(
            (p): IMentalSkillUpdateModel => ({
                ...p,
                [name]: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    ...p[name],
                    [choiceOne]: value,
                },
            })
        );
    };

    const handleChoiceTwoUpdated = (name: string, value: string): void => {
        setUpdate(
            (p): IMentalSkillUpdateModel => ({
                ...p,
                [name]: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    ...p[name],
                    [choiceTwo]: value,
                },
            })
        );
    };

    const handleDeleteButtonClicked = (): void => {
        setSaving(true);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const payload: ITrainingUpdateModel = {
            ...training,
        };

        const rest = payload.mentalSkills.filter(
            (s: IMentalSkillUpdateModel) => s.id !== update.id
        );
        payload.mentalSkills = [...rest];

        saveTraining(payload)
            .then(() => {
                setSaving(false);
                alert('Success!');

                push(`/trainings/${trainingId}/mentalskills`);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const handleSaveButtonClicked = (): void => {
        setSaving(true);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const payload: ITrainingUpdateModel = {
            ...training,
        };

        if (update.id === null) payload?.mentalSkills.push(update);
        else {
            const index = payload.mentalSkills.findIndex(
                (s: IMentalSkillUpdateModel) => s.id === update.id
            );
            payload.mentalSkills[index] = update;
        }

        saveTraining(payload)
            .then(() => {
                setSaving(false);
                alert('Success!');

                push(`/trainings/${trainingId}/mentalskills`);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const handleBackPressed = () => {
        push(`/trainings/${trainingId}/mentalskills`);
    };

    return (
        <>
            {isSaving && <Spinner />}

            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>
                Mental skills <Icon name="chevron-right" size={24} />{' '}
                {id ? mentalSkill?.name : 'New mental skill'}
            </PageTitle>

            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Delete" danger onClick={handleDeleteButtonClicked} />
                            <Button text="Cancel" onClick={handleBackPressed} secondary />
                            <Button text="Save" onClick={handleSaveButtonClicked} />
                        </div>
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Delete" danger onClick={handleDeleteButtonClicked} />
                            <Button text="Cancel" onClick={handleBackPressed} secondary />
                            <Button text="Save" onClick={handleSaveButtonClicked} />
                        </div>
                    </div>
                }
            >
                <SectionTitle>General information</SectionTitle>

                <TextInput label="Title" name="name" value={update.name} onChange={handleUpdated} />

                <Select
                    label="Type"
                    value={update.type}
                    onChange={handleUpdated}
                    name="type"
                    options={[
                        { key: 'Practice', value: 'Regular mental skills' },
                        { key: 'Situation', value: 'Situational mental skills' },
                        {
                            key: 'Match',
                            value: 'Match (Tactical Awareness)',
                        },
                        {
                            key: 'Recovery',
                            value: 'Recovery (Meditations)',
                        },
                        {
                            key: 'Special',
                            value: 'Special (Ideal Performance State, Advanced Tactical Awareness)',
                        },
                        {
                            key: 'Purpose',
                            value: 'Special - Purpose (Getting the purpose right)',
                        },
                    ]}
                />

                <FileInput
                    label="Background Image"
                    name="backgroundImage"
                    value={update.backgroundImage}
                    defaultValue={update.backgroundImageUrl}
                    onChange={handleUpdated}
                    type="image"
                />

                {update.type !== 'Special' &&
                    update.type !== 'Situation' &&
                    update.type !== 'Recovery' && (
                        <>
                            <SectionTitle>Assessment</SectionTitle>

                            <TextInput
                                label="Assessment Name"
                                name="assessmentName"
                                value={update.assessmentName}
                                onChange={handleUpdated}
                            />

                            <TextInput
                                label="Question"
                                name="assessmentQuestion"
                                value={update.assessmentQuestion}
                                onChange={handleUpdated}
                                multiline
                            />

                            <TextInput
                                label="Weight"
                                name="weight"
                                value={update.weight || ''}
                                onChange={handleUpdated}
                            />
                        </>
                    )}

                <SectionTitle>Training</SectionTitle>

                <TextInput
                    label="Repetitions"
                    name="requiredIterations"
                    value={update.requiredIterations || ''}
                    onChange={handleUpdated}
                />

                <SectionTitle>Reinforcement</SectionTitle>

                <Select
                    label="Reinforcement type"
                    value={update.reinforcementType}
                    onChange={handleReinforcementTypeChanged}
                    name="reinforcementType"
                    options={[
                        { key: 'Single', value: 'Single' },
                        { key: 'Multiple', value: 'Multiple' },
                    ]}
                />

                {update.reinforcementType === 'Single' && (
                    <>
                        <TextInput
                            label="Reinforcement Title (ex. believe and exclude confident body language!)"
                            name="reinforcementTitles"
                            value={update.reinforcementTitles.default}
                            onChange={handleSingleChoiceUpdated}
                        />
                    </>
                )}

                {update.reinforcementType === 'Multiple' && (
                    <>
                        <TextInput
                            label="Reinforcement Choice Question (ex. Before we start, we want to know about you..."
                            name="reinforcementChoiceQuestion"
                            value={update.reinforcementChoiceQuestion || ''}
                            onChange={handleUpdated}
                        />

                        <h2>Choice #1</h2>

                        <TextInput
                            label="Choice name (ex. Moderate intensity)"
                            name="title"
                            value={choiceOne}
                            onChange={(name, value) => {
                                setChoiceOne(value.toString());
                            }}
                        />

                        {!!choiceOne && (
                            <>
                                <TextInput
                                    label="Choice description (ex. I play my best tennis when I have a moderate intensity)"
                                    name="reinforcementChoices"
                                    value={update.reinforcementChoices[choiceOne]}
                                    onChange={handleChoiceOneUpdated}
                                />

                                <TextInput
                                    label="Reinforcement (ex. Beleive and exclude confident body language!)"
                                    name="reinforcementTitles"
                                    value={update.reinforcementTitles[choiceOne]}
                                    onChange={handleChoiceOneUpdated}
                                />
                            </>
                        )}

                        <h2>Choice #2</h2>

                        <TextInput
                            label="Choice name (ex. Higher intensity)"
                            name="title"
                            value={choiceTwo}
                            onChange={(name, value) => {
                                setChoiceTwo(value.toString());
                            }}
                        />

                        {choiceTwo && (
                            <>
                                <TextInput
                                    label="Choice description (ex. I play my best tennis when I have a moderate intensity)"
                                    name="reinforcementChoices"
                                    value={update.reinforcementChoices[choiceTwo]}
                                    onChange={handleChoiceTwoUpdated}
                                />

                                <TextInput
                                    label="Reinforcement (ex. Beleive and exclude confident body language!)"
                                    name="reinforcementTitles"
                                    value={update.reinforcementTitles[choiceTwo]}
                                    onChange={handleChoiceTwoUpdated}
                                />
                            </>
                        )}
                    </>
                )}
            </Section>
        </>
    );
}
