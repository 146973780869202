export enum VisualizationContentType {
    Default = 'Default',
    WithInspiringMusic = 'WithInspiringMusic',
    WithBrainWave = 'WithBrainWave',

    // @deprecated
    DefaultMaleAudio = 'DefaultMaleAudio',
    DefaultFemaleAudio = 'DefaultFemaleAudio',
    ShortMaleAudio = 'ShortMaleAudio',
    ShortFemaleAudio = 'ShortFemaleAudio',
}
