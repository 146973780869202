import * as React from 'react';
import * as Styled from './MultiSelect.styled';
import { Checkbox } from '../Checkbox';
import { Label } from '../Label';
import { IMultiSelectProps } from './MultiSelect.types';

export function MultiSelect(props: IMultiSelectProps): React.ReactElement {
    const { name, label, options, value, onChange, noMargin } = props;

    const handleChanged = (key: string) => {
        let currentValue = [...(value || [])];
        if (currentValue.indexOf(key) === -1) {
            currentValue.push(key);
        } else {
            currentValue = [...currentValue.filter((p) => p !== key)];
        }

        onChange(name, currentValue);
    };

    return (
        <Styled.Root noMargin={noMargin}>
            {label && <Label>{label}</Label>}
            {options.map((p) => (
                <Styled.CheckboxRoot key={p.key}>
                    <Checkbox
                        onChange={handleChanged}
                        name={p.key}
                        value={value?.indexOf(p.key) !== -1}
                        label={p.value}
                    />
                </Styled.CheckboxRoot>
            ))}
        </Styled.Root>
    );
}
