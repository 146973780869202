import React, { Fragment, useState } from 'react';
import { Spinner } from '../Spinner';
import * as Styled from './Table.styled';
import { ITableProps } from './Table.types';
import { NavLink } from 'react-router-dom';

export function Table(props: ITableProps): React.ReactElement {
    const { columns, data, isLoading, dense } = props;
    const [sortUp, setSortUp] = useState(false);

    return (
        <>
            <Styled.Root dense={dense}>
                <thead>
                    <tr>
                        {columns.map((c) => (
                            <Fragment key={c.key}>
                                {c.enableSort ? (
                                    <th
                                        style={c.style}
                                        onClick={() => {
                                            if (c.sortCallback) {
                                                c.sortCallback();
                                            }
                                            setSortUp(!sortUp);
                                        }}
                                    >
                                        {c.name}{' '}
                                        <i
                                            className={`bi ${
                                                sortUp ? `bi-sort-down` : `bi-sort-up`
                                            }`}
                                        ></i>
                                    </th>
                                ) : (
                                    <th style={c.style}>{c.name}</th>
                                )}
                            </Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!isLoading &&
                        data &&
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        data.map((d: any, index: number) => (
                            <tr key={index}>
                                {columns.map((c) => (
                                    <td
                                        key={c.key}
                                        style={{
                                            ...c.style,
                                            ...{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            },
                                        }}
                                    >
                                        {c.type === 'countLink' && (
                                            <Styled.CountLink to={d[c.linkKey!]}>
                                                {d[c.key]}
                                            </Styled.CountLink>
                                        )}
                                        {c.type === 'link' && (
                                            <NavLink style={{ fontWeight: 600 }} to={d[c.linkKey!]}>
                                                {d[c.linkText!]}
                                            </NavLink>
                                        )}
                                        {c.type === 'button' && (
                                            <Styled.LinkButton
                                                disabled={
                                                    c.buttonDisabledKey !== undefined &&
                                                    d[c.buttonDisabledKey!]
                                                }
                                                style={{ fontWeight: 600 }}
                                                onClick={(): void => d[c.buttonKey!](d)}
                                            >
                                                {d[c.buttonText!]}
                                            </Styled.LinkButton>
                                        )}
                                        {(!c.type || c.type === 'text') && d[c.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
            </Styled.Root>
            {isLoading && <Spinner hideOverlay={true} />}
            {!isLoading && data && data.length === 0 && (
                <Styled.NoData>There are no data</Styled.NoData>
            )}
        </>
    );
}
