import { AppConfig } from '../../../App.config';
import { HttpClient } from '../HttpClient';

class CoachClient {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public inviteTrainee(
        email: string | undefined,
        message: string | undefined,
        token: string,
        trainingId: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (!token) return null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            HttpClient.request<any>({
                method: 'post',
                url: `${AppConfig.endpoints.coach.url}/me/trainees`,
                token,
                data: { email, message },
                trainingId,
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    // TODO: error handler on here
                    reject(error.response?.data.error_description);
                });
        });
    }
}

const coachClient = new CoachClient();
export { coachClient as CoachClient };
