import * as React from 'react';
import { AppConfig } from '../../App.config';
import { ITraining } from '../../Models/ITraining';
import { Http } from '../Clients/Http';

export function useTraining(trainingId: string | undefined): ITraining | null {
    const [training, setTraining] = React.useState<ITraining | null>(null);

    React.useEffect(() => {
        if (!trainingId) return;

        Http.request<ITraining>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}`,
        }).then((data) => {
            setTraining(data);
        });
        // Test
    }, [trainingId]);

    return training;
}
