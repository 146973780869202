import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Color } from '../../Color';

export const Root = styled.div<{ noMargin?: boolean; vertical?: boolean }>`
    ${({ noMargin }): FlattenSimpleInterpolation | false =>
        !noMargin &&
        css`
            margin-bottom: 32px;
        `}

    margin-right: 24px;

    ${({ vertical }): FlattenSimpleInterpolation | false =>
        !!vertical &&
        css`
            margin-right: 0;
            margin-left: 24px;
            margin-bottom: 0;
        `}
`;

export const SelectInput = styled.select<{ hasError: boolean }>`
    height: 42px;
    font-size: 15px;
    border: 1px solid ${Color.DefaultBorder};
    min-width: 300px;
    border-radius: 4px;
    padding-left: 17px;
    padding-right: 17px;
    line-height: 38px;

    ${({ hasError }): FlattenSimpleInterpolation | false =>
        hasError &&
        css`
            border: 1px solid rgb(215, 58, 73);
        `}
`;
