import * as React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from './App.context';
import { BusinessDashboardScreen } from './Screens/BusinessDashboardScreen';
import { CompetitiveSkillsScreen } from './Screens/CompetitiveSkillsScreen';
import { CompetitiveSkillEditScreen } from './Screens/CompetitiveSkillsScreen/CompetitiveSkillEditScreen';
import { LoginScreen } from './Screens/LoginScreen';
import {
    MentalSkillContentsScreen,
    MentalSkillEditScreen,
    MentalSkillsScreen,
} from './Screens/MentalSkillsScreen';
import { ResourcesScreen } from './Screens/ResourcesScreen';
import { ResourceEditScreen } from './Screens/ResourcesScreen/ResourceEditScreen';
import { TrainingLibraryEditScreen } from './Screens/TrainingLibraryScreen';
import { TrainingLibraryScreen } from './Screens/TrainingLibraryScreen';
import { UsersScreen, UserDetailScreen } from './Screens/UsersScreen';
import { FindUserScreen } from './Screens/UsersScreen/FindUserScreen';
import { Auth } from './Shared/Clients/Auth';
import { Layout } from './Shared/Components/Layout';
import { RecommendationTestScreen } from './Screens/RecommendationTestScreen';
import { CoachDetailScreen, CoachesScreen } from './Screens/CoachesScreen';
import { CoachVideosScreen } from './Screens/CoachVideosScreen';
import { CoachVideoEditScreen } from './Screens/CoachVideosScreen/CoachVideoEditScreen';
import { TrainingSelectionScreen } from './Screens/TrainingSelectionScreen';

export function AppRoutes(): React.ReactElement {
    const [isSales, setSales] = React.useState<boolean>(false);

    React.useEffect(() => {
        Auth.isSales().then(setSales);
    }, []);

    return (
        <Layout>
            {!isSales && (
                <Switch>
                    <Route
                        path="/trainings/:trainingId"
                        component={BusinessDashboardScreen}
                        exact
                    />
                    <Route path="/trainings/:trainingId/users" component={UsersScreen} exact />
                    <Route
                        path="/trainings/:trainingId/find-user"
                        component={FindUserScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/trainees/:userId/:email"
                        component={UserDetailScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/mentalskills"
                        component={MentalSkillsScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/mentalskills/new"
                        component={MentalSkillEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/mentalskills/:id/contents"
                        component={MentalSkillContentsScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/mentalskills/:id"
                        component={MentalSkillEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/competitiveskills"
                        component={CompetitiveSkillsScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/competitiveskills/new"
                        component={CompetitiveSkillEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/competitiveskills/:id"
                        component={CompetitiveSkillEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/library"
                        component={TrainingLibraryScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/library/new"
                        component={TrainingLibraryEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/library/:id"
                        component={TrainingLibraryEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/resources"
                        component={ResourcesScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/resources/new"
                        component={ResourceEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/resources/:id"
                        component={ResourceEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/coach-videos"
                        component={CoachVideosScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/coach-videos/new"
                        component={CoachVideoEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/coach-videos/:id"
                        component={CoachVideoEditScreen}
                        exact
                    />
                    <Route
                        path="/trainings/:trainingId/tests/recommendations"
                        component={RecommendationTestScreen}
                        exact
                    />

                    <Route path="/trainings/:trainingId/coaches" component={CoachesScreen} exact />
                    <Route
                        path="/trainings/:trainingId/coaches/:userId"
                        component={CoachDetailScreen}
                        exact
                    />
                </Switch>
            )}

            {isSales && (
                <Switch>
                    <Route path="/trainings/:trainingId/coaches" component={CoachesScreen} exact />
                    <Route
                        path="/trainings/:trainingId/coaches/:userId"
                        component={CoachDetailScreen}
                        exact
                    />
                </Switch>
            )}
        </Layout>
    );
}

export function Routes(): React.ReactElement {
    const { pathname } = useLocation();
    const { push } = useHistory();
    const { isLoggedIn, updateContext } = React.useContext(AppContext);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    React.useEffect(() => {
        Auth.isLoggedIn().then((isLoggedIn) => {
            updateContext('isLoggedIn', isLoggedIn);
        });
    }, [updateContext]);

    React.useEffect(() => {
        if (isLoggedIn && pathname === '/') {
            push('/trainings');
        }
    }, [pathname, isLoggedIn, push]);

    return (
        <Switch>
            {isLoggedIn === null && <>Loading...</>}
            {isLoggedIn === false && (
                <>
                    <Redirect path="/" to="/auth" />
                    <Route path="/auth" component={LoginScreen} />j
                </>
            )}
            {isLoggedIn === true && (
                <>
                    <Route path="/trainings" component={TrainingSelectionScreen} exact />
                    <Route path="/trainings/:trainingId" component={AppRoutes} />
                </>
            )}
        </Switch>
    );
}
