import * as React from 'react';
import * as Styled from './Section.styled';
import { ISectionProps } from './Section.types';

export function Section(props: React.PropsWithChildren<ISectionProps>): React.ReactElement {
    const { heading, children, isDangerZone, header, footer, backgroundColor } = props;

    return (
        <Styled.Root isDangerZone={isDangerZone}>
            {heading && <Styled.Heading>{heading}</Styled.Heading>}
            {header && <Styled.Header>{header}</Styled.Header>}
            <Styled.Content hasHeading={!!heading} backgroundColor={backgroundColor}>
                {children}
            </Styled.Content>
            {footer && <Styled.Footer>{footer}</Styled.Footer>}
        </Styled.Root>
    );
}
