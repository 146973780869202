import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IFile } from '../../Models/IFile';
import { ICompetitiveSkill } from '../../Models/ITraining';
import { Button } from '../../Shared/Components/Button';
import { Icon } from '../../Shared/Components/Icon';
import { PageTitle, PageTitleDescription, SectionTitle } from '../../Shared/Components/Layout';
import { Spinner } from '../../Shared/Components/Spinner';
import { TextInput } from '../../Shared/Components/TextInput';
import { saveTraining } from '../../Shared/Helpers/SaveTraining';
import { useCompetitiveSkill } from '../../Shared/Hooks/useCompetitiveSkill';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { FileInput } from '../../Shared/Components/FileInput';
import { IMentalSkillUpdateModel } from '../../Models/ITrainingUpdateModel';

export function CompetitiveSkillEditScreen(): React.ReactElement {
    const { trainingId, id } = useParams<{ trainingId: string; id?: string }>();
    const [training, competitiveSkill, update, setUpdate] = useCompetitiveSkill(trainingId, id);
    const [isSaving, setSaving] = React.useState<boolean>(false);
    const { push } = useHistory();

    const handleBackPressed = () => {
        push(`/trainings/${trainingId}/competitiveskills`);
    };
    const handleDeleteButtonClicked = (): void => {
        setSaving(true);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const payload: ITrainingUpdateModel = {
            ...training,
        };

        const rest = payload.competitiveSkills.filter(
            (s: IMentalSkillUpdateModel) => s.id !== update.id
        );
        payload.competitiveSkills = [...rest];

        saveTraining(payload)
            .then(() => {
                setSaving(false);
                alert('Success!');

                push(`/trainings/${trainingId}/competitiveskills`);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };
    const handleUpdated = (name: string, value: IFile | null | string | number): void => {
        setUpdate((p) => ({
            ...p,
            [name]: value,
        }));
    };

    const handleSaveButtonClicked = (): void => {
        setSaving(true);

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const payload: ITrainingUpdateModel = {
            ...training,
        };

        if (update.id === null) payload?.competitiveSkills.push(update);
        else {
            const index = payload.competitiveSkills.findIndex(
                (s: ICompetitiveSkill) => s.id === update.id
            );
            payload.competitiveSkills[index] = update;
        }

        saveTraining(payload)
            .then(() => {
                setSaving(false);
                alert('Success!');

                push(`/trainings/${trainingId}/competitiveskills`);
            })
            .catch((e) => {
                alert(e);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <>
            {isSaving && <Spinner />}

            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>
                Competitive skills <Icon name="chevron-right" size={24} />{' '}
                {id ? competitiveSkill?.name : 'New competitive skill'}
            </PageTitle>

            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Delete" danger onClick={handleDeleteButtonClicked} />
                            <Button text="Cancel" onClick={handleBackPressed} secondary />
                            <Button text="Save" onClick={handleSaveButtonClicked} />
                        </div>
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Delete" danger onClick={handleDeleteButtonClicked} />
                            <Button text="Cancel" onClick={handleBackPressed} secondary />
                            <Button text="Save" onClick={handleSaveButtonClicked} />
                        </div>
                    </div>
                }
            >
                <SectionTitle>General information</SectionTitle>

                <TextInput label="Title" name="name" value={update.name} onChange={handleUpdated} />

                <Select
                    label="Type"
                    value={update.type}
                    onChange={handleUpdated}
                    name="type"
                    options={[
                        { key: 'Singles', value: 'Singles' },
                        { key: 'Doubles', value: 'Doubles' },
                    ]}
                />

                <FileInput
                    label="Background image"
                    name="backgroundImage"
                    value={update.backgroundImage}
                    defaultValue={update.backgroundImageUrl}
                    onChange={handleUpdated}
                    type="image"
                />

                <TextInput
                    label="Reinforcement statement"
                    name="reinforcementStatement"
                    value={update.reinforcementStatement}
                    onChange={handleUpdated}
                />
            </Section>
        </>
    );
}
