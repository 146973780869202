import styled, { css } from 'styled-components';
import { Color } from '../../Color';

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10000;
`;

export const Root = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10001;
`;

export const StandaloneRoot = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Spinner = styled.div<{ color?: string }>`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;

    ${({ color }) => css`
        border-top: 3px solid ${color || 'white'};
        border-right: 3px solid ${color || 'white'};
        border-bottom: 3px solid ${color || 'white'};
    `}

    border-left: 3px solid ${Color.Primary};
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinner 1.1s infinite linear;
    animation: spinner 1.1s infinite linear;

    &:after {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
`;
