import styled from 'styled-components';
import { Color } from '../../Color';

export const PageTitle = styled.h1`
    font-size: 1.875rem;
    line-height: 1.235;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 24px;
`;

export const PageTitleDescription = styled.p`
    font-family: 'DM Sans', sans-serif;
    line-height: 1.235;
    color: ${Color.SubText};
    margin-bottom: 5px;
`;

export const SectionTitle = styled.h2`
    margin: 0 0 30px;
    padding-top: 10px;
    font-size: 1.5em;
    line-height: 1.5;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
`;

export const SetTitle = styled.h3`
    margin: 0 0 30px;
    padding-top: 10px;
    font-size: 1.25em;
    line-height: 1.5;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
`;
export const SetTitleType = styled.h3`
    margin: 0 0 30px;
    padding-top: 10px;
    font-size: 1.5em;
    text-decoration: underline;
    line-height: 1.5;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
`;
