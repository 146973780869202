import { useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { Trainee } from '../../Models/Trainee';
import { Http } from '../Clients/Http';

export function useTrainee(trainingId: string, userId: string): Trainee | null {
    const [state, setState] = useState<Trainee | null>(null);

    useEffect(() => {
        Http.request<Trainee>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${userId}`,
        }).then(setState);
    }, [trainingId, userId]);

    return state;
}
