import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../Shared/Components/Button';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Table } from '../../Shared/Components/Table';
import { TextInput } from '../../Shared/Components/TextInput';
import { useTraining } from '../../Shared/Hooks/useTraining';

export function MentalSkillsScreen(): React.ReactElement {
    const { trainingId } = useParams<{ trainingId: string }>();
    const training = useTraining(trainingId);
    const { push } = useHistory();
    const [filter, setFilter] = React.useState<string>('');
    const [search, setSearch] = React.useState<string>('');
    const isLoading = !training;

    const handleAddNewButtonClicked = (): void => {
        push(`/trainings/${trainingId}/mentalskills/new`);
    };

    const tableData = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        training?.mentalSkills.forEach((m) => {
            if (filter && filter !== m.type) return;
            if (search && m.name.toLowerCase().indexOf(search.toLowerCase()) === -1) return;

            result.push({
                name: m.name,
                type: m.type,
                weight: m.weight === -1 ? 'N/A' : m.weight,
                requiredIterations: m.requiredIterations,
                editLink: `/trainings/${trainingId}/mentalskills/${m.id}`,
                editLinkText: 'Edit',
                orderLink: `/trainings/${trainingId}/mentalskills/${m.id}/contents`,
                orderLinkText: 'View',
            });
        });

        return result;
    }, [training, trainingId, search, filter]);

    return (
        <>
            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>Mental skills</PageTitle>

            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextInput
                            name="search"
                            value={search}
                            placeholder="Start typing to search..."
                            onChange={(name: string, newValue: string) => setSearch(newValue)}
                            noMargin
                        />
                        <Select
                            name="filter"
                            value={filter}
                            placeholder="All"
                            options={[
                                {
                                    key: 'Practice',
                                    value: 'Practice',
                                },
                                {
                                    key: 'Situation',
                                    value: 'Situation',
                                },
                                {
                                    key: 'Match',
                                    value: 'Match',
                                },
                                {
                                    key: 'Special',
                                    value: 'Special',
                                },
                                {
                                    key: 'Purpose',
                                    value: 'Special - Purpose',
                                },
                            ]}
                            onChange={(name, newValue) => setFilter(newValue)}
                            noMargin
                        />
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Add new" onClick={handleAddNewButtonClicked} />
                        </div>
                    </div>
                }
            >
                <Table
                    isLoading={isLoading}
                    columns={[
                        { key: 'name', name: 'Name', style: { textAlign: 'left' } },
                        {
                            key: 'type',
                            name: 'Type',
                            style: { textAlign: 'left' },
                        },
                        {
                            key: 'requiredIterations',
                            name: 'Repetitions',
                            style: { textAlign: 'center' },
                        },
                        {
                            key: 'weight',
                            name: 'Weight',
                            style: { textAlign: 'center' },
                        },
                        {
                            key: 'edit',
                            name: 'Actions',
                            type: 'link',
                            linkKey: 'editLink',
                            linkText: 'editLinkText',
                            style: { textAlign: 'center' },
                        },
                        {
                            key: 'order',
                            name: 'Contents',
                            type: 'link',
                            linkKey: 'orderLink',
                            linkText: 'orderLinkText',
                            style: { textAlign: 'center' },
                        },
                    ]}
                    data={tableData}
                />
            </Section>
        </>
    );
}
