import { useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { ICoach } from '../../Models/ICoach';
import { Http } from '../Clients/Http';

export function useCoachDetail(userId: string): ICoach | null {
    const [state, setState] = useState<ICoach | null>(null);

    useEffect(() => {
        Http.request<ICoach>({
            url: `${AppConfig.endpoints.admin.url}/coaches/${userId}`,
        }).then(setState);
    }, [userId]);

    return state;
}
