import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { IResourceQueryModel } from '../../Models/IResourceQueryModel';
import { IResourceUpdateModel } from '../../Models/IResourceUpdateModel';
import { markdownEngine } from '../../Screens/ResourcesScreen/ResourceEditScreen';
import { Http } from '../Clients/Http';

const defaultText = `This is an example content. To start a new article, just delete the content in this editor.

# Heading Level 1
## Heading Level 2
### Heading Level 3

If you start a line with #, it becomes a heading. If you just write text, it becomes a paragraph. 
you can also style your text **bold**, _italic_, ~~strikethrough~~, and [Link](https://www.google.com). 
Currently, there is no option for underlines. 

# Using images

The following line shows how you can use external/public images. 

![Image Example](https://via.placeholder.com/600x300.png)

If you want to upload a new image and use it, you can use the attachment section below this editor. 

1. Press the "Browse" button
2. Select a picture
3. The picture will be uploaded to our content storage
4. Wait for preview to show
5. Click on preview, and the code snippet containing the image will be copied to the clipboard
6. Ctrl + v on the editor screen

**Note**: You can add as many images as you like.`;

export function useResource(
    trainingId: string,
    resourceId?: string
): [boolean, IResourceUpdateModel, Dispatch<SetStateAction<IResourceUpdateModel>>] {
    const [isReady, setReady] = useState<boolean>(false);
    const [update, setUpdate] = useState<IResourceUpdateModel>({
        id: null,
        trainingId,
        title: '',
        resourceType: 'Article',
        availableOn: 'Trainee',
        markdownContent: '',
        htmlContent: '',
        backgroundImageUrl: '',
        videoThumbnailUrl: '',
        linkedVideoUrl: '',
        organicVideoUrl: '',
        description: '',
        author: '',
        authorLink: '',
        subscriptionLevel: 0,
        contentDate: new Date(),
        situations: [],
        mentalSkills: [],
        attachments: [],
        searchHelpers: [],
        backgroundImage: null,
        organicVideo: null,
        videoThumbnail: null,
    });

    useEffect(() => {
        if (resourceId === undefined) {
            setUpdate((p) => ({
                ...p,
                markdownContent: defaultText,
                htmlContent: markdownEngine.render(defaultText),
            }));

            setReady(true);
        } else {
            Http.request<IResourceQueryModel>({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/resources/${resourceId}`,
            }).then((resource: IResourceQueryModel): void => {
                setUpdate((p) => ({
                    ...p,
                    ...resource,
                    htmlContent: markdownEngine.render(resource.markdownContent),
                }));

                setReady(true);
            });
        }
    }, [resourceId, trainingId]);

    return [isReady, update, setUpdate];
}
