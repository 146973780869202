import { ValidationErrors } from '../../Models/ValidationErrors';

export enum ValidationType {
    NullOrUndefined = 'NullOrUndefined',
    EmptyString = 'EmptyString',
    Number = 'Number',
    MinimumCharacter = 'MinimumCharacter',
    Regex = 'Regex',
    EmptyArray = 'EmptyArray',
}

export class Guard {
    private errors: ValidationErrors = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstNullOrUndefined(value: any, name: string, message: string): Guard {
        if (Guard.isNullOrUndefined(value)) this.errors[name] = message;

        return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstEmptyString(value: any, name: string, message: string): Guard {
        if (Guard.isNullOrUndefined(value) || value.trim().length === 0)
            this.errors[name] = message;

        return this;
    }

    public againstMinimumCharacter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value: any,
        minChar: number,
        name: string,
        message: string
    ): Guard {
        if (Guard.isNullOrUndefined(value) || value.trim().length < minChar)
            this.errors[name] = message;

        return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstInvalidDate(value: any, name: string, message: string): Guard {
        if (
            Guard.isNullOrUndefined(value) ||
            !(value as Date).getTime ||
            isNaN((value as Date).getTime())
        )
            this.errors[name] = message;

        return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstNonNumber(value: any, name: string, message: string): Guard {
        if (isNaN(value)) this.errors[name] = message;

        return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstLessThan(value: any, minValue: number, name: string, message: string): Guard {
        if (isNaN(value) || value < minValue) this.errors[name] = message;

        return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstGreaterThan(value: any, maxValue: number, name: string, message: string): Guard {
        if (isNaN(value) || value > maxValue) this.errors[name] = message;

        return this;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public againstEmptyArray(value: any, name: string, message: string): Guard {
        if (!Array.isArray(value) || value.length === 0) this.errors[name] = message;

        return this;
    }

    public getResult(): ValidationErrors {
        return this.errors;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static isNullOrUndefined(value: any): boolean {
        return value === undefined || value === null;
    }
}

export const Regex = {
    EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
};
