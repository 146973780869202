import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { IHttpError, IHttpRequest, IHttpResult } from './HttpClient.types';

class HttpClient {
    public request<T>(request: IHttpRequest): Promise<IHttpResult<T>> {
        return new Promise(async (resolve, reject: (error: IHttpError) => void) => {
            const { url, method, data, token, trainingId } = request;
            const config: AxiosRequestConfig = {
                url,
                method: method || 'get',
                headers: {
                    'Training-Id': trainingId,
                    'Content-Type': 'application/json',
                },
                data,
            };

            // @ts-ignore
            if (token) config.headers['Authorization'] = `Bearer ${token}`;

            Axios.request(config)
                .then(({ data, status }) => {
                    console.log({ resultData: data, status: status.toString() });

                    resolve({ data, status });
                })
                .catch((e: AxiosError) => {
                    const errorResponse = {
                        error: e.response?.data,
                        status: e.response?.status,
                    };

                    console.log({
                        error: e.response?.data ?? '',
                        status: e.response?.status?.toString() ?? '',
                    });
                    // @ts-ignore
                    reject(errorResponse);
                });
        });
    }
}

const httpClient = new HttpClient();
export { httpClient as HttpClient };
