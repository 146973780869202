import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Spinner } from '../../Shared/Components/Spinner';
import { Table } from '../../Shared/Components/Table';
import { TextInput } from '../../Shared/Components/TextInput';
import { useResources } from '../../Shared/Hooks/useResources';

export function ResourcesScreen(): React.ReactElement {
    // @ts-ignore
    const { trainingId } = useParams();
    const [resources] = useResources(trainingId);
    const [filter, setFilter] = React.useState<string>('');
    const [search, setSearch] = React.useState<string>('');
    const { push, go } = useHistory();
    const [saving, setSaving] = React.useState<boolean>(false);
    const isLoading = resources === null;

    const handleAddNewButtonClicked = (): void => {
        push(`/trainings/${trainingId}/resources/new`);
    };

    const handleDeleteButtonClicked = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (r: any): Promise<void> => {
            if (
                window.confirm(
                    `This will permanently delete this resource: "${r.title}", and is not reversible. Continue?`
                )
            ) {
                try {
                    setSaving(true);

                    await Http.request({
                        url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/resources/${r.id}`,
                        method: 'delete',
                    });

                    alert('Success!');
                } catch {
                    alert('Failed!');
                } finally {
                    setSaving(false);
                    go(0);
                }
            }
        },
        [go, trainingId]
    );

    const tableData = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        resources.forEach((r) => {
            if (filter && r.resourceType !== filter) return;
            if (
                search &&
                (r.title.toLowerCase().indexOf(search.toLowerCase()) === -1 ||
                    r.description.toLowerCase().indexOf(search.toLowerCase()) === -1)
            )
                return;

            result.push({
                id: r.id,
                type: r.resourceType,
                title: r.title,
                author: r.author,
                editLinkText: 'Edit',
                editLink: `/trainings/${trainingId}/resources/${r.id}`,
                deleteButtonText: 'Delete',
                deleteCallback: handleDeleteButtonClicked,
            });
        });

        return result;
    }, [resources, trainingId, handleDeleteButtonClicked, filter, search]);

    return (
        <>
            {saving && <Spinner />}

            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>Resources</PageTitle>

            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextInput
                            name="search"
                            value={search}
                            placeholder="Start typing to search..."
                            onChange={(name: string, newValue: string) => setSearch(newValue)}
                            noMargin
                        />
                        <Select
                            name="filter"
                            value={filter}
                            placeholder="All"
                            options={[
                                {
                                    key: 'Article',
                                    value: 'Article',
                                },
                                {
                                    key: 'Video',
                                    value: 'Video',
                                },
                            ]}
                            onChange={(name, newValue) => setFilter(newValue)}
                            noMargin
                        />
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Add new" onClick={handleAddNewButtonClicked} />
                        </div>
                    </div>
                }
            >
                <Table
                    isLoading={isLoading}
                    columns={[
                        {
                            key: 'title',
                            name: 'Title',
                            style: { textAlign: 'left', maxWidth: 150 },
                        },
                        { key: 'type', name: 'Type', style: { textAlign: 'left', maxWidth: 50 } },
                        {
                            key: 'author',
                            name: 'Author',
                            style: { textAlign: 'left', maxWidth: 50 },
                        },
                        {
                            key: 'edit',
                            name: 'Actions',
                            type: 'link',
                            linkKey: 'editLink',
                            linkText: 'editLinkText',
                            style: { textAlign: 'center' },
                        },
                        {
                            key: 'delete',
                            name: 'Delete',
                            type: 'button',
                            buttonText: 'deleteButtonText',
                            buttonKey: 'deleteCallback',
                            style: { textAlign: 'center' },
                        },
                    ]}
                    data={tableData}
                />
            </Section>
        </>
    );
}
