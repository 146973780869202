import styled from 'styled-components';
import { Color } from '../../../Color';

export const Root = styled.header`
    height: 70px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
`;

export const HomeLink = styled.a`
    display: block;
`;

export const Logo = styled.img.attrs({
    src: '/assets/Logo_White.png',
})`
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: auto;
    margin-right: 10px;
`;

export const Brand = styled.p`
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
    color: ${Color.DefaultText};
`;
