import * as React from 'react';

type Order = { [key: string]: number };
type Item = { id: string };
type MoveFn = (item: Item) => void;

export function useContentOrder(
    contents: { id: string; order?: number }[] | null
): [Order, MoveFn, MoveFn] {
    const [order, setOrder] = React.useState<Order>({});

    React.useEffect(() => {
        if (contents != null) {
            const result: { [key: string]: number } = {};
            contents.sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1));
            contents.forEach((p, index: number) => {
                result[p.id] = index
            });

            setOrder(result);
        }
    }, [contents]);

    const moveUp = (item: Item): void => {
        setOrder((p) => {
            const prevIndex = order[item.id];
            let lastItem: string | null = null;

            let lastItemIndex = -1;
            for (const i in order) {
                const index = order[i];

                if (index < prevIndex && index > lastItemIndex) {
                    lastItemIndex = index;
                    lastItem = i;
                }
            }

            return {
                ...p,
                [item.id]: lastItemIndex,
                ...(lastItem ? {[lastItem]: prevIndex} : {}),
            };
        });
    };

    const moveDown = (item: Item): void => {
        setOrder((p) => {
            const prevIndex = order[item.id];
            let nextItem: string | null = null;

            let nextItemIndex = -1;
            for (const i in order) {
                const index = order[i];
                if (index > nextItemIndex) nextItemIndex = index;
            }

            for (const i in order) {
                const index = order[i];

                if (index > prevIndex && index <= nextItemIndex) {
                    nextItemIndex = index;
                    nextItem = i;
                }
            }

            return {
                ...p,
                [item.id]: nextItemIndex,
                ...(nextItem ? {[nextItem]: prevIndex} : {}),
            };
        });
    };

    return [order, moveUp, moveDown];
}
