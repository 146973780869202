import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { AppConfig } from '../../../App.config';
import { Http } from '../Http';

class Blob {
    private async initialize(containerName: string): Promise<ContainerClient> {
        return new Promise((resolve, reject) => {
            Http.request<string>({
                url: `${AppConfig.endpoints.admin.url}/contents/url/${containerName}`,
            })
                .then((data) => {
                    const client = new BlobServiceClient(data);

                    resolve(client.getContainerClient(containerName));
                })
                .catch((e) => {
                    alert('Authorization failed');
                    console.log(e);

                    reject(e);
                });
        });
    }

    public async upload(containerName: string, filename: string, data: string) {
        const containerClient = await this.initialize(containerName);
        const blobClient = containerClient.getBlockBlobClient(filename);
        const base64 = data.split(',')[1];
        const buffer = new Buffer(base64, 'base64');

        await blobClient.upload(buffer, buffer.length);
    }
}

const blob = new Blob();
export { blob as Blob };
