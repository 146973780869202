import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Color } from '../../Color';

export const Root = styled.div<{ noMargin?: boolean }>`
    ${({ noMargin }): FlattenSimpleInterpolation | false =>
        !noMargin &&
        css`
            margin-bottom: 32px;
        `}

    margin-right: 24px;
`;

export const Input = styled.input<{ hasError: boolean; width?: number }>`
    background-color: #ffffff;
    border: 1px solid ${Color.DefaultBorder};
    border-radius: 4px;
    padding-left: 17px;
    padding-right: 17px;
    line-height: 38px;
    width: 600px;
    box-sizing: border-box;
    font-size: 16px;

    ${({ hasError }): FlattenSimpleInterpolation | false =>
        hasError &&
        css`
            border: 1px solid rgb(215, 58, 73);
        `}

    ${({ width }): FlattenSimpleInterpolation | false | undefined | number =>
        width &&
        css`
            width: ${width}px;
        `}
`;

export const MultilineInput = styled.textarea<{ hasError: boolean }>`
    background-color: #ffffff;
    border: 1px solid ${Color.DefaultBorder};
    border-radius: 4px;
    padding: 17px;
    width: 600px;
    box-sizing: border-box;
    min-width: 400px;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    resize: none;
    height: 200px;

    ${({ hasError }): FlattenSimpleInterpolation | false =>
        hasError &&
        css`
            border: 1px solid rgb(215, 58, 73);
        `}
`;
