import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../App.context';
import { Auth } from '../../Shared/Clients/Auth';
import { ILoginInput } from '../../Shared/Clients/Auth/Auth.types';
import * as Styled from './LoginScreen.styled';
import { LoginTextField } from './LoginTextField';

export function LoginScreen(): React.ReactElement {
    const { push } = useHistory();
    const { updateContext } = React.useContext(AppContext);
    const [inputModel, setInputModel] = React.useState<ILoginInput>({
        email: '',
        password: '',
    });

    const handleChanged = (name: string, value: string): void => {
        setInputModel((p) => ({
            ...p,
            [name]: value,
        }));
    };

    const handleSignInButtonPressed = async (): Promise<void> => {
        await Auth.login(inputModel);
        const isLoggedIn = await Auth.isLoggedIn();

        if (isLoggedIn) {
            updateContext('isLoggedIn', isLoggedIn);

            push('/trainings');
        }
    };

    return (
        <Styled.Root>
            <Styled.Login>
                <Styled.SubHeading>APEAK ADMIN</Styled.SubHeading>
                <Styled.Heading>Login</Styled.Heading>

                <LoginTextField
                    name="email"
                    label="Email"
                    value={inputModel.email}
                    placeholder="apeak@apeak.com"
                    onChange={handleChanged}
                    marginBottom={30}
                />

                <LoginTextField
                    name="password"
                    label="Password"
                    value={inputModel.password}
                    placeholder="*********"
                    onChange={handleChanged}
                    password
                    marginBottom={60}
                />

                <Styled.SignInButton onClick={handleSignInButtonPressed}>
                    Sign in
                </Styled.SignInButton>
            </Styled.Login>
        </Styled.Root>
    );
}
