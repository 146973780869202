import Axios, { AxiosRequestConfig } from 'axios';
import { Auth } from '../Auth';

class Http {
    public request<T>(request: AxiosRequestConfig): Promise<T> {
        return new Promise(async (resolve, reject) => {
            const { url, method, data, ...other } = request;
            const token = await Auth.getAccessToken();

            Axios.request({
                url,
                method: method || 'get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data,
                ...other,
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.href = '/';
                    }

                    reject(error);
                });
        });
    }
}

const http = new Http();
export { http as Http };
