import * as React from 'react';
import { AppConfig } from '../../App.config';
import { IResourceQueryModel } from '../../Models/IResourceQueryModel';
import { Http } from '../Clients/Http';

export function useResources(trainingId: string): [IResourceQueryModel[]] {
    const [resources, setResources] = React.useState<IResourceQueryModel[]>([]);

    React.useEffect(() => {
        Http.request<IResourceQueryModel[]>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/resources`,
        }).then((data) => {
            setResources(data);
        });
    }, [trainingId]);

    return [resources];
}
