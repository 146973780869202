import styled from 'styled-components';

export const PageDescription = styled.div`
    margin-bottom: 48px;
`;

export const InputErrorMessage = styled.div`
    margin-top: 5px;
    font-size: 15px;
    color: rgb(215, 58, 73);
    font-weight: 600;
`;

export const InputHintMessage = styled.div`
    margin-top: 5px;
    font-size: 13px;
    color: #8c8c8c;
`;

export const RequiredIndicator = styled.span`
    color: rgb(215, 58, 73);

    &:after {
        font-weight: 600;
        content: ' *';
    }
`;
