import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTraining } from '../../../Hooks/useTraining';
import * as Styled from './Header.styled';

export function Header(): React.ReactElement {
    const { trainingId } = useParams<{ trainingId?: string }>();
    const training = useTraining(trainingId);

    return (
        <Styled.Root>
            <Styled.HomeLink href="/">
                <Styled.Logo />
                <Styled.Brand>
                    Apeak Admin {training != null ? `- ${training.name}` : ''}
                </Styled.Brand>
            </Styled.HomeLink>
        </Styled.Root>
    );
}
