import Axios from 'axios';
import decode from 'jwt-decode';
import { AppConfig } from '../../../App.config';
import { SessionStorage } from '../SessionStorage';
import { AuthCacheKey, GrantType, ILoginInput, ITokenResponse, TokenStatus } from './Auth.types';

class Auth {
    public async isLoggedIn(): Promise<boolean> {
        const tokenStatus: TokenStatus = await this.getAccessTokenStatus();

        switch (tokenStatus) {
            case TokenStatus.NoToken:
                return false;
            case TokenStatus.ValidToken:
                return true;
            case TokenStatus.ExpiredToken:
                return false;
            // try {
            // await this.authenticateWithRefreshToken();
            //
            // return true;
            // } catch {
            //     return false;
            // }
        }
    }

    public async login(loginInput: ILoginInput): Promise<void> {
        await this.acquireToken(GrantType.External, null, {
            type: 'SignIn',
            username: loginInput.email,
            password: loginInput.password,
        });
    }

    public async getAccessToken(): Promise<string> {
        const accessToken = await this.getCachedAccessToken();
        if (accessToken) return accessToken;

        // accessToken = await this.authenticateWithRefreshToken();
        //
        // return accessToken;

        return '';
    }

    public async isMasterAdmin(): Promise<boolean> {
        const accessToken = await this.getAccessToken();
        if (!accessToken) return false;

        const decoded: { role: string } = decode(accessToken);
        if (!decoded) return false;

        return decoded.role === 'MasterAdmin';
    }

    public async isSales(): Promise<boolean> {
        const accessToken = await this.getAccessToken();
        if (!accessToken) return false;

        const decoded: { role: string } = decode(accessToken);
        if (!decoded) return false;

        return decoded.role === 'Sales';
    }

    public async acquireToken(
        grantType: GrantType,
        token: string | null,
        additionalParams: { [key: string]: string }
    ) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('grant_type', grantType);
            formData.append('client_id', process.env.REACT_APP_CLIENT_ID as string);
            formData.append('provider', 'Local');
            formData.append(
                'scope',
                `Apeak.MentalTraining Apeak.Membership Apeak.Identity Apeak.Admin offline_access openid`
            );
            for (const key in additionalParams) formData.append(key, additionalParams[key]);

            Axios.request({
                method: 'post',
                url: `${AppConfig.endpoints.identity.url}/connect/token`,
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(async (response) => {
                    const tokenResponse: ITokenResponse = response.data;
                    SessionStorage.set(AuthCacheKey.AccessToken, tokenResponse.access_token);
                    SessionStorage.set(AuthCacheKey.RefreshToken, tokenResponse.refresh_token);
                    SessionStorage.set(
                        AuthCacheKey.AccessTokenExpiration,
                        (new Date().getTime() + (tokenResponse.expires_in - 60) * 1000).toString()
                    );

                    resolve(tokenResponse.access_token);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error.response.data.error_description);
                });
        });
    }

    private async getCachedAccessToken(): Promise<string | null> {
        const cachedTokenStatus = await this.getAccessTokenStatus();
        if (cachedTokenStatus !== TokenStatus.ValidToken) return null;

        return SessionStorage.get(AuthCacheKey.AccessToken);
    }

    private async getAccessTokenStatus(): Promise<TokenStatus> {
        const expiration: null | string = SessionStorage.get(AuthCacheKey.AccessTokenExpiration);
        if (!expiration) return TokenStatus.NoToken;

        return parseInt(expiration) > new Date().getTime()
            ? TokenStatus.ValidToken
            : TokenStatus.ExpiredToken;
    }
}

const auth = new Auth();
export { auth as Auth };
