import { useEffect } from 'react';
import * as React from 'react';
import { AppConfig } from '../../../App.config';
import { IFile } from '../../../Models/IFile';
import { Http } from '../../Clients/Http';
import { Color } from '../../Color';
import { Button } from '../Button';
import { Label } from '../Label';
import { InputErrorMessage, InputHintMessage, RequiredIndicator } from '../Typography';
import * as Styled from './FileInput.styled';
import { IFileInputProps } from './FileInput.types';

export function FileInput(props: IFileInputProps): React.ReactElement {
    const {
        name,
        label,
        onChange,
        value,
        type,
        defaultValue,
        required,
        hint,
        errorMessage,
    } = props;
    const ref = React.useRef<HTMLInputElement | null>(null);
    const [audioUrl, setAudioUrl] = React.useState<string | null>(null);

    useEffect(() => {
        if (!defaultValue) return;

        if (type === 'audio' || type === 'video') {
            Http.request<string>({
                url: `${AppConfig.endpoints.admin.url}/trainings/contents/${defaultValue}/url`,
            })
                .then((data) => {
                    setAudioUrl(data);
                })
                .catch(console.log);
        }
    }, [defaultValue, type]);

    const handleFileSelected = (): void => {
        if (!ref.current) return;
        if (ref.current?.files?.length === 0) return;
        const file = ref.current!.files![0];

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (): void => {
            const base64 = reader.result?.toString();
            if (!base64) throw new Error('File not loaded properly');

            const split = file.name.split('.');
            const extension = split[split.length - 1];
            const result: IFile = {
                name: file.name,
                extension,
                base64,
            };

            onChange(name, result);
        };

        reader.onerror = console.log;
    };

    const handleBrowseButtonClicked = (): void => {
        if (!ref.current) return;

        ref.current.click();
    };

    const handleDeleted = (): void => {
        onChange(name, null);
    };

    const getAcceptedFiles = (): string => {
        switch (type) {
            case 'audio':
                return 'audio/mpeg, audio/mp3';
            case 'image':
                return 'image/png, image/jpeg';
            case 'video':
                return 'video/mpeg, video/mp4';
            default:
                return '';
        }
    };

    return (
        <Styled.Root>
            {label && (
                <Label>
                    {label} {required && <RequiredIndicator />}
                </Label>
            )}

            {hint && <InputHintMessage style={{ marginBottom: 10 }}>{hint}</InputHintMessage>}
            {errorMessage && (
                <InputErrorMessage style={{ marginBottom: 10 }}>{errorMessage}</InputErrorMessage>
            )}

            {(value || defaultValue) && (
                <>
                    {type === 'image' && (
                        <Styled.ImageWrapper>
                            <Styled.ImagePreview src={value?.base64 || defaultValue} />
                        </Styled.ImageWrapper>
                    )}
                    {type === 'audio' && audioUrl && !value && (
                        <>
                            <div style={{ marginBottom: 5 }}>Current:</div>
                            <audio controls style={{ marginBottom: 20 }}>
                                <source src={audioUrl} type="audio/mp3" />
                            </audio>
                        </>
                    )}
                    {type === 'audio' && value && (
                        <>
                            <div
                                style={{ marginBottom: 5, color: Color.Highlight, fontWeight: 600 }}
                            >
                                Updated:
                            </div>
                            <audio controls style={{ marginBottom: 20 }}>
                                <source src={value.base64} type="audio/mp3" />
                            </audio>
                        </>
                    )}
                    {type === 'video' && audioUrl && !value && (
                        <>
                            <div style={{ marginBottom: 5 }}>Current:</div>
                            <video controls style={{ marginBottom: 20 }} width={400} height={300}>
                                <source src={audioUrl} type="video/mp4" />
                            </video>
                        </>
                    )}
                    {type === 'video' && value && (
                        <>
                            <div
                                style={{ marginBottom: 5, color: Color.Highlight, fontWeight: 600 }}
                            >
                                Updated:
                            </div>
                            <video controls style={{ marginBottom: 20 }} width={400} height={300}>
                                <source src={value.base64} type="video/mp4" />
                            </video>
                        </>
                    )}
                </>
            )}
            <Styled.ControlRoot>
                <Button onClick={handleBrowseButtonClicked} text="Browse" secondary noMargin />
                {(type === 'audio' || type === 'video') && (value || defaultValue) && (
                    <Button onClick={handleDeleted} text="Delete" danger />
                )}
                {value && <Styled.File>{value.name}</Styled.File>}
            </Styled.ControlRoot>

            <Styled.HiddenInput
                ref={ref}
                type="file"
                accept={getAcceptedFiles()}
                onChange={handleFileSelected}
            />
        </Styled.Root>
    );
}
