import * as React from 'react';
import { AppContext } from '../../../App.context';
import { AppContextState } from './AppContextProvider.types';

export function AppContextProvider(props: React.PropsWithChildren<{}>): React.ReactElement {
    const { children } = props;
    const [appContext, setAppContext] = React.useState<AppContextState>({
        isLoggedIn: null,
    });

    const updateContext = React.useCallback(
        (key: string, value: string | boolean): void => {
            setAppContext((p) => ({
                ...p,
                [key]: value,
            }));
        },
        [setAppContext]
    );

    return (
        <AppContext.Provider
            value={{
                ...appContext,
                updateContext,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
