import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export const Root = styled.div<{ noMargin?: boolean }>`
    ${({ noMargin }): FlattenSimpleInterpolation | false =>
        !noMargin &&
        css`
            margin-bottom: 32px;
        `}

    margin-right: 24px;
`;

export const CheckboxRoot = styled.div`
    margin-bottom: 8px;
`;
