import styled, { css } from 'styled-components';
import { Color } from '../../Color';

export const Root = styled.button<{
    noMargin?: boolean;
    secondary?: boolean;
    danger?: boolean;
    reject?: boolean;
}>`
    color: white;
    background-color: ${Color.Primary};
    border-radius: 4px;
    height: 42px;
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    border: 1px solid ${Color.Primary};
    min-width: 150px;
    margin-left: 24px;

    ${({ noMargin }) =>
        noMargin &&
        css`
            margin-left: 0;
        `}

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: white;
            color: ${Color.Primary};
            border: 1px solid ${Color.Primary};
        `}

    ${({ danger }) =>
        danger &&
        css`
            background-color: ${Color.Danger};
            color: white;
            border: 1px solid ${Color.Danger};
        `}

    ${({ reject }) =>
        reject &&
        css`
            background-color: white;
            color: ${Color.Danger};
            border: 1px solid ${Color.Danger};
        `}
`;
