import * as React from 'react';
import * as Styled from './ResourceAttachments.styled';
import { IResourceAttachmentsProps } from './ResourceAttachments.types';

export function ResourceAttachments(props: IResourceAttachmentsProps): React.ReactElement | null {
    const { attachments } = props;
    const ref = React.useRef<HTMLInputElement>(null);

    const copyToClipboard = (link: string): void => {
        if (!ref.current) return;

        ref.current.value = `![figure](${link})`;
        ref.current.focus();
        ref.current.select();
        document.execCommand('copy');
    };

    if (attachments.length <= 0) return null;

    return (
        <Styled.Root>
            <p>Click on image to copy Markdown code to clipboard</p>

            <Styled.List>
                {attachments.map((a) => (
                    <Styled.ItemRoot key={a}>
                        <Styled.Preview src={a} onClick={(): void => copyToClipboard(a)} />
                    </Styled.ItemRoot>
                ))}
            </Styled.List>

            <Styled.HiddenTextArea ref={ref} />
        </Styled.Root>
    );
}
