import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { Icon } from '../../Shared/Components/Icon';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import { Spinner } from '../../Shared/Components/Spinner';
import { Table } from '../../Shared/Components/Table';
import { useContentOrder } from '../../Shared/Hooks/useContentOrder';
import { useMentalSkill } from '../../Shared/Hooks/useMentalSkill';
import { useMentalSkillContents } from '../../Shared/Hooks/useMentalSkillContents';
import { Section } from '../../Shared/Components/Section';

export function MentalSkillContentsScreen(): React.ReactElement {
    const [isSaving, setSaving] = React.useState<boolean>(false);
    const { go } = useHistory();
    const { trainingId, id } = useParams<{ trainingId: string; id: string }>();
    const [, mentalSkill] = useMentalSkill(trainingId, id);
    const [contents] = useMentalSkillContents(trainingId, id);
    const [order, moveUp, moveDown] = useContentOrder(contents);
    const isLoading = contents === null;

    const tableData = React.useMemo(() => {
        if (contents.length !== Object.keys(order).length) return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        contents?.forEach((m) => {
            result.push({
                id: m.id,
                name: m.name,
                order: order[m.id],
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                type: m.type,
                moveUpButtonText: 'Up',
                moveUpFn: moveUp,
                moveUpDisabled: false,
                moveDownButtonText: 'Down',
                moveDownFn: moveDown,
                moveDownDisabled: false,
                editLinkText: 'Edit',
                editLink: `/trainings/${trainingId}/library/${m.id}`,
            });
        });

        result.sort((a, b) => (a.order > b.order ? 1 : -1));

        if (result.length > 0) {
            result[0].moveUpDisabled = true;
            result[result.length - 1].moveDownDisabled = true;
        }

        return result;
    }, [contents, moveUp, moveDown, trainingId, order]);

    const saveOrder = async (): Promise<void> => {
        setSaving(true);

        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/contents/mentalskills/${id}/order`,
                method: 'post',
                data: {
                    contentOrder: order,
                },
            });

            alert('Success!');
            setSaving(false);
            go(0);
        } catch (e) {
            alert(e);
        } finally {
            setSaving(false);
        }
    };

    return (
        <>
            {isSaving && <Spinner />}

            <PageTitleDescription>Content Management</PageTitleDescription>
            <PageTitle>
                Mental skills <Icon name="chevron-right" size={24} /> {mentalSkill?.name}{' '}
                <Icon name="chevron-right" size={24} /> Contents
            </PageTitle>

            <Section
                header={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {/*<Button text="Cancel" onClick={handleBackPressed} secondary />*/}
                        <Button text="Save" onClick={saveOrder} />
                    </div>
                }
            >
                <Table
                    isLoading={isLoading}
                    columns={[
                        {
                            key: 'moveUp',
                            name: 'Up',
                            type: 'button',
                            buttonText: 'moveUpButtonText',
                            buttonKey: 'moveUpFn',
                            buttonDisabledKey: 'moveUpDisabled',
                            style: { textAlign: 'center', width: 50 },
                        },
                        {
                            key: 'moveDown',
                            name: 'Down',
                            type: 'button',
                            buttonText: 'moveDownButtonText',
                            buttonKey: 'moveDownFn',
                            buttonDisabledKey: 'moveDownDisabled',
                            style: { textAlign: 'center', width: 50 },
                        },
                        { key: 'name', name: 'Name', style: { textAlign: 'left' } },
                        {
                            key: 'type',
                            name: 'Type',
                            style: { textAlign: 'left' },
                        },
                        {
                            key: 'edit',
                            name: 'Actions',
                            type: 'link',
                            linkKey: 'editLink',
                            linkText: 'editLinkText',
                            style: { textAlign: 'center' },
                        },
                    ]}
                    data={tableData}
                />
            </Section>
        </>
    );
}
