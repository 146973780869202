import { useState, useEffect } from 'react';
import { AppConfig } from '../../App.config';
import { ITrainingListQueryModel } from '../../Models/ITrainingListQueryModel';
import { Http } from '../Clients/Http';

export function useTrainings(): ITrainingListQueryModel[] | null {
    const [trainings, setTrainings] = useState<ITrainingListQueryModel[] | null>(null);

    useEffect(() => {
        Http.request<ITrainingListQueryModel[]>({
            url: `${AppConfig.endpoints.admin.url}/trainings`,
        }).then((data) => {
            setTrainings(data);
        });
    }, []);

    return trainings;
}
