import { useEffect, useState } from 'react';
import { AppConfig } from '../../App.config';
import { ITrainingContent } from '../../Models/ITrainingContent';
import { Http } from '../Clients/Http';

export function useTrainingContents(trainingId: string): ITrainingContent[] | null {
    const [visualizations, setVisualizations] = useState<ITrainingContent[] | null>(null);

    useEffect(() => {
        Http.request<ITrainingContent[]>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/contents`,
        }).then((data) => {
            setVisualizations(data);
        });
    }, [trainingId]);

    return visualizations;
}
