import Markdown from 'markdown-it';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/theme/mdn-like.css';
import CodeMirror from 'codemirror';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { IFile } from '../../Models/IFile';
import { IResourceUpdateModel } from '../../Models/IResourceUpdateModel';
import { ValidationErrors } from '../../Models/ValidationErrors';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { Checkbox } from '../../Shared/Components/Checkbox';
import { FileInput } from '../../Shared/Components/FileInput';
import { MainHeader } from '../../Shared/Components/Layout/MainHeader';
import { MultiSelect } from '../../Shared/Components/MultiSelect';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Spinner } from '../../Shared/Components/Spinner';
import { TagInput } from '../../Shared/Components/TagInput';
import { TextInput } from '../../Shared/Components/TextInput';
import { ContentUploader } from '../../Shared/Components/Uploader/ContentUploader';
import { UploadContentType } from '../../Shared/Components/Uploader/Uploader.types';
import { useResource } from '../../Shared/Hooks/useResource';
import { useTraining } from '../../Shared/Hooks/useTraining';
import { Guard } from '../../Shared/Utils/Guard';
import { InputGroup } from '../../Shared/Components/InputGroup';
import { ResourceAttachments } from './ResourceAttachments';
import { ResourcePreview } from './ResourcePreview';
import { SectionTitle } from '../../Shared/Components/Layout';
import { Label } from '../../Shared/Components/Label';

export const markdownEngine = new Markdown();

export function ResourceEditScreen(): React.ReactElement {
    const { push } = useHistory();
    const [errors, setErrors] = React.useState<ValidationErrors>({});
    const { id, trainingId } = useParams<{ id?: string; trainingId: string }>();
    const training = useTraining(trainingId);
    const [isReady, update, setUpdate] = useResource(trainingId, id);
    const [showSpinner, setSpinner] = React.useState<boolean>(false);
    const [showPreview, setShowPreview] = React.useState<boolean>(false);
    const [previewType, setPreviewType] = React.useState<'mobile' | 'desktop'>('desktop');
    const ref = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        if (!isReady) return;

        const codeMirror = CodeMirror(
            (e) => {
                if (!ref.current) return;

                ref.current?.parentNode?.replaceChild(e, ref.current);
            },
            {
                lineNumbers: true,
                mode: 'markdown',
                theme: 'mdn-like',
                lineWrapping: true,
                value: update.markdownContent,
            }
        );

        codeMirror.on('change', (editor) => {
            const value = editor.getDoc().getValue();
            const html = markdownEngine.render(value);

            setUpdate(
                (p: IResourceUpdateModel): IResourceUpdateModel => ({
                    ...p,
                    markdownContent: value,
                    htmlContent: html,
                    description: html
                        .replace(/<(.|\n)*?>/g, ' ')
                        .replaceAll('\n', ' ')
                        .substring(0, 150)
                        .trim(),
                })
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, isReady]);

    const handleUpdated = (
        name: string,
        value: string | IFile | number | null | string[] | boolean
    ): void => {
        let subscriptionLevel = update.subscriptionLevel;

        if (name === 'subscriptionLevel') {
            subscriptionLevel = parseInt(value as string);
        }

        setUpdate((p) => ({
            ...p,
            [name]: value,
            subscriptionLevel,
        }));
    };

    const handleImageSelected = async (name: string, file: IFile): Promise<void> => {
        try {
            const data = await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/resources/attachments`,
                method: 'post',
                data: {
                    attachmentType: 'Image',
                    ...file,
                },
                responseType: 'text',
            });

            setUpdate((p) => ({
                ...p,
                attachments: [...p.attachments, data as string],
            }));
        } catch {}
    };

    const isPayloadValid = (): boolean => {
        const validationErrors = new Guard()
            .againstMinimumCharacter(update.title, 2, 'title', 'Title is invalid.')
            .againstEmptyString(update.resourceType, 'resourceType', 'Type is invalid.')
            .getResult();

        if (!update.author && !!update.authorLink)
            validationErrors.authorLink = 'Author link is provided without author.';
        if (!update.backgroundImageUrl && !update.backgroundImage)
            validationErrors.backgroundImageUrl = 'Background image is required.';
        if (update.resourceType === 'Video') {
            if (!update.videoThumbnailUrl && !update.videoThumbnail)
                validationErrors.videoThumbnailUrl = 'Video thumbnail is required.';
            if (!update.linkedVideoUrl && !update.organicVideoUrl && !update.organicVideo) {
                validationErrors.linkedVideoUrl = 'Linked video or organic video is required.';
                validationErrors.organicVideoUrl = 'Linked video or organic video is required.';
            }
        }

        setErrors(validationErrors);

        return Object.keys(validationErrors).length === 0;
    };

    const handleSaveRequested = async (): Promise<void> => {
        if (!isPayloadValid()) return;

        try {
            setSpinner(true);

            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/resources`,
                method: 'post',
                data: {
                    ...update,
                },
            });

            alert('Success!');
            push(`/trainings/${trainingId}/resources`);
        } catch {
            alert('Failed!');
        } finally {
            setSpinner(false);
        }
    };

    const handleMobilePreviewButtonPressed = (): void => {
        setPreviewType('mobile');
        setShowPreview(true);
    };

    const handleDesktopPreviewButtonPressed = (): void => {
        setPreviewType('desktop');
        setShowPreview(true);
    };

    const handleClose = (): void => {
        setShowPreview(false);
    };

    return (
        <>
            {showSpinner && <Spinner />}
            <MainHeader renderLeft={() => <>{!update.id ? 'New resource' : 'Edit resource'}</>} />
            <Section
                header={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            text="Mobile preview"
                            onClick={handleMobilePreviewButtonPressed}
                            secondary
                        />
                        <Button
                            text="Desktop preview"
                            onClick={handleDesktopPreviewButtonPressed}
                            secondary
                        />

                        <Button text="Save" onClick={handleSaveRequested} />
                    </div>
                }
                footer={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            text="Mobile preview"
                            onClick={handleMobilePreviewButtonPressed}
                            secondary
                        />
                        <Button
                            text="Desktop preview"
                            onClick={handleDesktopPreviewButtonPressed}
                            secondary
                        />

                        <Button text="Save" onClick={handleSaveRequested} />
                    </div>
                }
            >
                <SectionTitle>General information</SectionTitle>

                <InputGroup>
                    <Checkbox
                        name="isHowToApeakContent"
                        value={update.isHowToApeakContent || false}
                        label="How to Apeak content"
                        onChange={handleUpdated}
                    />
                </InputGroup>

                <Select
                    label="Subscription level"
                    name="subscriptionLevel"
                    value={(update.subscriptionLevel || 0).toString()}
                    options={[
                        { value: 'Free', key: '0' },
                        { value: 'Premium', key: '1' },
                        { value: 'Superior', key: '2' },
                    ]}
                    onChange={handleUpdated}
                    hint="Select Free for all users and Basic for active users. More options to be added as more tiers are added."
                />

                <TextInput
                    name="title"
                    value={update.title}
                    label="Title"
                    onChange={handleUpdated}
                    errorMessage={errors.title}
                    required
                />

                <Select
                    label="Type"
                    name="resourceType"
                    value={update.resourceType}
                    options={[
                        { key: 'Article', value: 'Article' },
                        { key: 'Video', value: 'Video' },
                    ]}
                    onChange={handleUpdated}
                    errorMessage={errors.resourceType}
                    required
                />

                <Select
                    label="Available On"
                    name="availableOn"
                    value={update.availableOn}
                    options={[
                        { key: 'Trainee', value: 'Trainee' },
                        { key: 'Coach', value: 'Coach' },
                        { key: 'Both', value: 'Both' },
                    ]}
                    onChange={handleUpdated}
                    errorMessage={errors.availableOn}
                    required
                />

                <TextInput
                    name="author"
                    value={update.author}
                    label="Author name"
                    onChange={handleUpdated}
                    hint="This could be the name of an author such as 'Brian Park' or source name such as 'The New York Times' if you are providing a link."
                />

                <TextInput
                    name="authorLink"
                    value={update.authorLink}
                    label="Author link"
                    onChange={handleUpdated}
                    hint="Link to author's profile or the new source"
                    errorMessage={errors.authorLink}
                />

                <FileInput
                    name="backgroundImage"
                    required
                    value={update.backgroundImage}
                    onChange={handleUpdated}
                    type="image"
                    defaultValue={update?.backgroundImageUrl}
                    label="Background image"
                    errorMessage={errors.backgroundImageUrl}
                    hint="Thumbnail image for articles, or header image for videos on web"
                />

                {update.resourceType === 'Video' && (
                    <>
                        <SectionTitle>Video</SectionTitle>

                        <TextInput
                            name="linkedVideoUrl"
                            value={update.linkedVideoUrl}
                            label="External video url"
                            onChange={handleUpdated}
                            hint="Provide URL to youtube. For example: https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                            errorMessage={errors.linkedVideoUrl}
                        />

                        <Label>Organic video</Label>
                        <ContentUploader
                            containerName="videos"
                            contentType={UploadContentType.Video}
                            name="organicVideoUrl"
                            value={update?.organicVideoUrl}
                            onChange={handleUpdated}
                        />

                        <FileInput
                            name="videoThumbnail"
                            value={update.videoThumbnail}
                            onChange={handleUpdated}
                            type="image"
                            defaultValue={update?.videoThumbnailUrl}
                            label="Video Thumbnail"
                            errorMessage={errors.videoThumbnailUrl}
                        />
                    </>
                )}

                <TagInput
                    label="Tags for recommendation"
                    name="searchHelpers"
                    value={update.searchHelpers}
                    onChange={handleUpdated}
                />

                <p style={{ marginBottom: 22 }}>Mental skills</p>

                <MultiSelect
                    name="mentalSkills"
                    options={
                        training?.mentalSkills?.map((p) => ({ key: p.id, value: p.name })) ?? []
                    }
                    value={update.mentalSkills}
                    onChange={handleUpdated}
                />

                <p style={{ marginBottom: 22 }}>Situations</p>

                <MultiSelect
                    name="situations"
                    options={
                        training?.mentalSkills?.map((p) => ({ key: p.id, value: p.name })) ?? []
                    }
                    value={update.situations}
                    onChange={handleUpdated}
                />

                <SectionTitle>Text content</SectionTitle>

                <InputGroup>
                    <textarea id="resource-input" ref={ref} />
                </InputGroup>

                <ResourceAttachments attachments={update.attachments} />

                <FileInput
                    label="Upload image"
                    name="image"
                    value={null}
                    onChange={handleImageSelected}
                    type="image"
                />
            </Section>

            {showPreview && (
                <ResourcePreview html={update.htmlContent} type={previewType} close={handleClose} />
            )}
        </>
    );
}
