import * as React from 'react';
import { AppConfig } from '../../App.config';
import { ITrainingContent } from '../../Models/ITrainingContent';
import { Http } from '../Clients/Http';

export function useMentalSkillContents(
    trainingId: string,
    mentalSkillId: string
): [ITrainingContent[]] {
    const [contents, setContents] = React.useState<ITrainingContent[]>([]);

    React.useEffect(() => {
        Http.request<ITrainingContent[]>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/contents/mentalskills/${mentalSkillId}`,
        }).then((data) => {
            setContents(data);
        });
    }, [trainingId, mentalSkillId]);

    return [contents];
}
