import styled from 'styled-components';

export const Root = styled.div`
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(193 202 215 / 25%) 0px 2px 2.5px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
`;
