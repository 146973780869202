import React, { ReactElement, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageTitle, PageTitleDescription } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { Select } from '../../Shared/Components/Select';
import { Table } from '../../Shared/Components/Table';
import { TextInput } from '../../Shared/Components/TextInput';
import { useCoaches } from '../../Shared/Hooks/useCoaches';
import { getDateText } from '../UsersScreen';
import { Button } from '../../Shared/Components/Button';
import * as Styled from './CoachesScreen.styled';

enum SortKey {
    CREATED_DATE = 'createdDate',
    INVITATIONS = 'invitations',
    ACCEPTED_TRAINEES = 'Accepted_Trainees',
}

enum Status {
    APPROVED = 'Approved',
    PENDING = 'Pending',
}

// no need status
enum UserType {
    COACH = 'Coach',
    PARENT = 'Parent',
}

export function CoachesScreen(): ReactElement {
    const { trainingId } = useParams<{ trainingId: string }>();
    const [coaches] = useCoaches();
    const [selectedSortKey, setSelectedSortKey] = useState<SortKey>();
    const [ascending, setAscending] = useState(false);
    const [filter, setFilter] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [loop, setLoop] = useState<number>(0);

    const approved = () => {
        if (loop === 1) {
            setLoop(0);
        } else {
            setLoop(1);
        }
    };
    const pending = () => {
        if (loop === 2) {
            setLoop(0);
        } else {
            setLoop(2);
        }
    };

    const tableData = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];
        // console.log(coaches);
        if (loop === 0) {
            coaches?.forEach((v) => {
                const name = `${v.coachInfo?.firstName} ${v.coachInfo?.lastName}`;
                const email = v.coachInfo?.email?.toLowerCase() || '';
                const status = v.isVerified ? Status.APPROVED : Status.PENDING;
                const partnershipCode = v.partnershipCode;
                // no need status
                const userType =
                    v.coachInfo?.userType === UserType.COACH ? UserType.COACH : UserType.PARENT;
                const createdDate = getDateText(new Date(v.createdDate));
                // const status = v?.attestation.isVerified === true ? Status.APPROVED : Status.PENDING;
                // const status = v?.traineeAssignments?.filter(
                //     (assignment) => assignment?.initialEmail === 'parsaabida@gmail.com'
                // )?.length;
                const invitations = v?.traineeAssignments?.length ?? 0;
                const connectedTrainees = v?.traineeAssignments?.filter(
                    (assignment) => assignment?.status === 'Accepted'
                )?.length;

                if (filter && filter !== userType) return;
                if (search && name.indexOf(search) === -1 && email.indexOf(search) === -1) return;

                result.push({
                    name,
                    email,
                    status,
                    partnershipCode,
                    createdDate,
                    invitations,
                    connectedTrainees,
                    detailLinkText: 'Details',
                    detailLink: `/trainings/${trainingId}/coaches/${v.userId}`,
                });
            });
        } else if (loop === 1) {
            const approvedUsers = coaches?.filter((i) => i.isVerified === true);
            console.log('approvedUsers', approvedUsers);
            approvedUsers?.forEach((v) => {
                const name = `${v.coachInfo?.firstName} ${v.coachInfo?.lastName}`;
                const email = v.coachInfo?.email?.toLowerCase() || '';
                const status = v.isVerified ? Status.APPROVED : Status.PENDING;
                const partnershipCode = v.partnershipCode;
                // no need status
                const userType =
                    v.coachInfo?.userType === UserType.COACH ? UserType.COACH : UserType.PARENT;
                const createdDate = getDateText(new Date(v.createdDate));
                // const status = v?.attestation.isVerified === true ? Status.APPROVED : Status.PENDING;
                // const status = v?.traineeAssignments?.filter(
                //     (assignment) => assignment?.initialEmail === 'parsaabida@gmail.com'
                // )?.length;
                const invitations = v?.traineeAssignments?.length ?? 0;
                const connectedTrainees = v?.traineeAssignments?.filter(
                    (assignment) => assignment?.status === 'Accepted'
                )?.length;

                if (filter && filter !== userType) return;
                if (search && name.indexOf(search) === -1 && email.indexOf(search) === -1) return;

                result.push({
                    name,
                    email,
                    status,
                    createdDate,
                    invitations,
                    partnershipCode,
                    connectedTrainees,
                    detailLinkText: 'Details',
                    detailLink: `/trainings/${trainingId}/coaches/${v.userId}`,
                });
            });
        } else if (loop === 2) {
            const pendingUsers = coaches?.filter((i) => i.isVerified === false);
            console.log('pendingUsers', pendingUsers);
            pendingUsers?.forEach((v) => {
                const name = `${v.coachInfo?.firstName} ${v.coachInfo?.lastName}`;
                const email = v.coachInfo?.email?.toLowerCase() || '';
                const status = v.isVerified ? Status.APPROVED : Status.PENDING;
                const partnershipCode = v.partnershipCode;
                // no need status
                const userType =
                    v.coachInfo?.userType === UserType.COACH ? UserType.COACH : UserType.PARENT;
                const createdDate = getDateText(new Date(v.createdDate));
                // const status = v?.attestation.isVerified === true ? Status.APPROVED : Status.PENDING;
                // const status = v?.traineeAssignments?.filter(
                //     (assignment) => assignment?.initialEmail === 'parsaabida@gmail.com'
                // )?.length;
                const invitations = v?.traineeAssignments?.length ?? 0;
                const connectedTrainees = v?.traineeAssignments?.filter(
                    (assignment) => assignment?.status === 'Accepted'
                )?.length;

                if (filter && filter !== userType) return;
                if (search && name.indexOf(search) === -1 && email.indexOf(search) === -1) return;

                result.push({
                    name,
                    email,
                    status,
                    createdDate,
                    invitations,
                    partnershipCode,
                    connectedTrainees,
                    detailLinkText: 'Details',
                    detailLink: `/trainings/${trainingId}/coaches/${v.userId}`,
                });
            });
        }

        return result;
    }, [coaches, trainingId, filter, search, loop]);

    const exportToCsv = () => {
        const filename = `${filter || 'All'} coaches - ${new Date().toDateString()}.csv`;
        const rows = coaches
            ?.filter((item) => {
                return item?.coachInfo?.userType === filter;
            })
            .map((coach) => {
                return [
                    `${coach.coachInfo?.firstName ?? ''} ${coach.coachInfo?.lastName ?? ''}`,
                    coach.coachInfo?.email ?? '',
                    coach.coachInfo?.phoneNumber ?? '',
                    coach.coachInfo?.location ?? '',
                    coach.createdDate,
                    coach.coachInfo?.isSelfEmployed ? 'Yes' : 'No',
                    String(coach.coachInfo?.coachingExperienceInYears ?? 0),
                    String(coach?.traineeAssignments?.length ?? 0),
                    String(
                        coach?.traineeAssignments?.filter(
                            (assignment) => assignment?.status === 'Accepted'
                        )?.length ?? 0
                    ),
                ];
            });
        const processRow = (row: string[]) => {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                const innerValue = row[j];
                let result = (innerValue || '').replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
                if (j > 0) finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile =
            'Name,Email,Phone Number,Work Place,Created Date,Self Employed,Years of Coaching Experience,Invitations,Connected Trainees\n';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        // @ts-ignore
        if (navigator.msSaveBlob) {
            // @ts-ignore
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const sortCoach = (sortKey: SortKey) => {
        setAscending(!ascending);
        setSelectedSortKey(sortKey);
    };

    const sortedTableData = (sortKey?: SortKey) => {
        if (sortKey === SortKey.CREATED_DATE) {
            return ascending
                ? tableData.sort(
                      (data1, data2) =>
                          Date.parse(data1.createdDate) - Date.parse(data2.createdDate)
                  )
                : tableData.sort(
                      (data1, data2) =>
                          Date.parse(data2.createdDate) - Date.parse(data1.createdDate)
                  );
        } else if (sortKey === SortKey.INVITATIONS) {
            return ascending
                ? tableData.sort((data1, data2) => data1?.invitations - data2?.invitations)
                : tableData.sort((data1, data2) => data2.invitations - data1?.invitations);
        } else if (sortKey === SortKey.ACCEPTED_TRAINEES) {
            return ascending
                ? tableData.sort(
                      (data1, data2) => data1?.connectedTrainees - data2?.connectedTrainees
                  )
                : tableData.sort(
                      (data1, data2) => data2.connectedTrainees - data1?.connectedTrainees
                  );
        } else {
            return tableData;
        }
    };

    return (
        <>
            <PageTitleDescription>App Management</PageTitleDescription>
            <PageTitle>Coaches</PageTitle>

            <Section
                header={
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <TextInput
                                    name="search"
                                    value={search}
                                    placeholder="Start typing to search..."
                                    onChange={(name: string, newValue: string) =>
                                        setSearch(newValue)
                                    }
                                    noMargin
                                />
                                <Select
                                    name="filter"
                                    value={filter}
                                    placeholder="All"
                                    options={[
                                        // {
                                        //     key: Status.APPROVED,
                                        //     value: Status.APPROVED,
                                        // },
                                        // {
                                        //     key: Status.PENDING,
                                        //     value: Status.PENDING,
                                        // },
                                        {
                                            key: UserType.COACH,
                                            value: UserType.COACH,
                                        },
                                        {
                                            key: UserType.PARENT,
                                            value: UserType.PARENT,
                                        },
                                    ]}
                                    onChange={(name, newValue) => setFilter(newValue)}
                                    noMargin
                                />
                                <Styled.Approved selected={loop === 1} onClick={approved}>
                                    Approved
                                </Styled.Approved>
                                <Styled.Pending selected={loop === 2} onClick={pending}>
                                    Pending
                                </Styled.Pending>
                            </div>
                            <Button text="Export" onClick={exportToCsv} />
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <span>Advanced users: </span>
                            <strong>
                                {coaches.length > 0
                                    ? coaches.filter((data) => data.isVerified === true).length
                                    : 0}
                            </strong>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <span>Team users: </span>
                            <strong>
                                {coaches.length > 0
                                    ? coaches.filter((data) => data.isVerified === false).length
                                    : 0}
                            </strong>
                        </div>
                    </div>
                }
            >
                <Table
                    isLoading={coaches.length === 0}
                    columns={[
                        {
                            key: 'name',
                            name: 'Name',
                            style: { textAlign: 'left', maxWidth: 80 },
                        },
                        {
                            key: 'email',
                            name: 'Email',
                            style: { textAlign: 'left', maxWidth: 100 },
                        },
                        {
                            key: 'status',
                            name: 'Status',
                            style: { textAlign: 'left', maxWidth: 50 },
                        },
                        {
                            key: 'partnershipCode',
                            name: 'Code',
                            style: { textAlign: 'left', maxWidth: 50 },
                        },
                        {
                            key: 'invitations',
                            name: 'Invtd.',
                            style: { textAlign: 'right', maxWidth: 50 },
                            enableSort: true,
                            sortCallback: () => sortCoach(SortKey.INVITATIONS),
                        },
                        {
                            key: 'connectedTrainees',
                            name: 'Accptd.',
                            style: { textAlign: 'right', maxWidth: 50 },
                            enableSort: true,
                            sortCallback: () => sortCoach(SortKey.ACCEPTED_TRAINEES),
                        },
                        {
                            key: 'createdDate',
                            name: 'Created',
                            style: { textAlign: 'left', maxWidth: 50, cursor: 'pointer' },
                            enableSort: true,
                            sortCallback: () => sortCoach(SortKey.CREATED_DATE),
                        },
                        {
                            key: 'details',
                            name: 'Actions',
                            type: 'link',
                            linkKey: 'detailLink',
                            linkText: 'detailLinkText',
                            style: { textAlign: 'center', maxWidth: 50 },
                        },
                    ]}
                    data={sortedTableData(selectedSortKey)}
                />
            </Section>
        </>
    );
}
