import { AppConfig } from '../../App.config';
import { Http } from './Http';

class AnalyticsClient {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async query(query: string): Promise<any[][]> {
        console.log(query);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result = await Http.request<{ tables: any }>({
            url: `${AppConfig.endpoints.admin.url}/analytics`,
            method: 'post',
            data: {
                query,
            },
        });
        console.log(result);
        if (result.tables.length > 0) {
            return result.tables[0].rows;
        } else {
            return [];
        }
    }
}

const analyticsClient = new AnalyticsClient();
export { analyticsClient as AnalyticsClient };
