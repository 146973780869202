import * as React from 'react';

export function Icon(props: {
    name: string;
    size: number;
    marginRight?: number;
}): React.ReactElement {
    return (
        <i
            className={`bi bi-${props.name}`}
            style={{
                fontSize: props.size,
                marginRight: props.marginRight || 0,
            }}
        />
    );
}
