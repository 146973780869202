import * as React from 'react';
import * as Styled from './TagInput.styled';
import { ITagInputProps } from './TagInput.types';
import { Label } from '../Label';

export function TagInput(props: ITagInputProps): React.ReactElement {
    const { value, onChange, label, name } = props;

    const handleChanged = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.keyCode !== 13) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const newValue = event.target.value;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        event.target.value = '';

        onChange(name, [...value, newValue]);
    };

    const handleDeleted = (deletedTag: string): void => {
        const index = value.indexOf(deletedTag);
        if (index === -1) return;

        const copy = [...value];
        copy.splice(index, 1);

        onChange(name, copy);
    };

    return (
        <Styled.Root>
            <Label>{label}</Label>
            <Styled.InputRoot>
                <Styled.TagInput onKeyUp={handleChanged} />
            </Styled.InputRoot>
            <Styled.TagList>
                {value.map((t, index: number) => (
                    <Styled.Tag key={`t${index}`} onClick={() => handleDeleted(t)}>
                        {t}
                        {/*<DeleteTagButton value={t} onClick={handleDeleted} />*/}
                    </Styled.Tag>
                ))}
            </Styled.TagList>
        </Styled.Root>
    );
}
