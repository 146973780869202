import styled from 'styled-components';
import { Color } from '../../Shared/Color';
import { SetTitle } from '../../Shared/Components/Layout';

export const RecommendationResultRoot = styled.div`
    margin-bottom: 20px;
`;

export const ResultTitle = styled(SetTitle)`
    margin-bottom: 12px;
`;

export const ResultDescription = styled.p`
    margin-bottom: 6px;
    color: ${Color.SubText};
`;
