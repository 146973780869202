import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { IUserQueryModel } from '../../Models/IUserQueryModel';
import { PageTitleDescription, PageTitle } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { TextInput } from '../../Shared/Components/TextInput';
import { Button } from '../../Shared/Components/Button';
import { Http } from '../../Shared/Clients/Http';

export function FindUserScreen(): React.ReactElement {
    const [search, setSearch] = React.useState<string>('');
    const history = useHistory();
    const { trainingId } = useParams<{ trainingId: string }>();

    const handleFindUser = () => {
        if (search.length < 3) {
            alert('Please use a valid email address.');

            return;
        }

        Http.request<IUserQueryModel>({
            url: `${AppConfig.endpoints.identity.url}/admin/account/${search}`,
            responseType: 'text',
        })
            .then(({ userId, ...otherDetails }) => {
                history.push(`/trainings/${trainingId}/trainees/${userId}/${search}`);
            })
            .catch(() => {
                alert('No user found with the email.');
                setSearch('');
            });
    };

    return (
        <>
            <PageTitleDescription>App Management</PageTitleDescription>
            <PageTitle>Find user</PageTitle>

            <Section>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextInput
                        name="search"
                        value={search}
                        placeholder="Enter email address and press Find"
                        onChange={(name: string, newValue: string) => setSearch(newValue)}
                        noMargin
                    />

                    <Button text="Find" onClick={handleFindUser} noMargin />
                </div>
            </Section>
        </>
    );
}
