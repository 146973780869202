import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Color } from '../../Color';
import { ICheckboxTheme } from './Checkbox.types';

export const Root = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    background: none;
    text-align: left;
    flex-shrink: 0;
`;

export const Label = styled.p`
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 25px;
`;

export const CheckboxRoot = styled.div<ICheckboxTheme>`
    min-width: 25px;
    width: 25px;
    height: 25px;
    background-color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 50%;
    display: flex;

    ${({ isChecked }): false | undefined | FlattenSimpleInterpolation =>
        isChecked &&
        css`
            border-color: ${Color.Highlight};
            background-color: ${Color.Highlight};
        `}

    ${({ hasError }): false | undefined | FlattenSimpleInterpolation =>
        hasError &&
        css`
            border-color: #ff003d;
        `}
`;

export const Check = styled.img`
    width: 14px;
    height: auto;
`;
