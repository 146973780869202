import * as React from 'react';
import * as Styled from './RecommendationTestScreen.styled';
import { useParams } from 'react-router-dom';
import { AppConfig } from '../../App.config';
import { IPostTrainingRecommendationInsight } from '../../Models/IPostTrainingRecommendationInsight';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { PageTitle, PageTitleDescription, SectionTitle } from '../../Shared/Components/Layout';
import { Section } from '../../Shared/Components/Section';
import { TextInput } from '../../Shared/Components/TextInput';

export function RecommendationTestScreen(): React.ReactElement {
    const { trainingId } = useParams<{ trainingId: string }>();
    const [reflection, setReflection] = React.useState<string>('');
    const [result, setResult] = React.useState<IPostTrainingRecommendationInsight[]>([]);

    const getRecommendationInsights = async (): Promise<void> => {
        const data = await Http.request<IPostTrainingRecommendationInsight[]>({
            url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/tests/posttraining`,
            method: 'post',
            data: {
                reflection,
            },
        });

        setResult(data);
    };

    const getReasonText = (reason: string): string => {
        switch (reason) {
            case 'RequiredTwoResources':
                return 'One of two required resources';
            case 'RequiredTwoInnerVoices':
                return 'One of two required inner voices';
            case 'RequiredTwoVisualizations':
                return 'One of two required visualizations';
            case 'FillingInWithVisualization':
                return 'Filled in place of no matching resources, or inner voice';
            case 'FillingInWithMeditation':
                return 'Filled in place of no matching resources, inner voice, or visualization';
            default:
                return 'N/A';
        }
    };

    const getTags = (tags: { [key: string]: number }): string => {
        let result = '';
        for (const tag in tags) {
            result += `${tag}(+${tags[tag]}) `;
        }

        return result;
    };

    return (
        <>
            <PageTitleDescription>Testing Tools</PageTitleDescription>
            <PageTitle>Recommendation Tests</PageTitle>
            <Section
                header={
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Button text="Submit" onClick={getRecommendationInsights} />
                        </div>
                    </div>
                }
            >
                <SectionTitle>Post-training recommendation</SectionTitle>

                <TextInput
                    name="reflection"
                    value={reflection}
                    label="Reflection"
                    onChange={(name, value): void => {
                        setReflection(value as string);
                        setResult([]);
                    }}
                    multiline
                    required
                />

                {result.length > 0 && (
                    <>
                        <SectionTitle>Recommendation Results</SectionTitle>

                        {result.map((p, index) => (
                            <Styled.RecommendationResultRoot key={p.title}>
                                <Styled.ResultTitle>
                                    {index + 1}: {p.title}
                                </Styled.ResultTitle>
                                <Styled.ResultDescription>
                                    Score: <b>{p.score || 'N/A'}</b>
                                </Styled.ResultDescription>
                                <Styled.ResultDescription>
                                    Tags: <b>{getTags(p.matches) || 'N/A'}</b>
                                </Styled.ResultDescription>
                                <Styled.ResultDescription>
                                    Type: <b>{p.type}</b>
                                </Styled.ResultDescription>
                                <Styled.ResultDescription>
                                    Reason: <b>{getReasonText(p.reason)}</b>
                                </Styled.ResultDescription>
                            </Styled.RecommendationResultRoot>
                        ))}
                    </>
                )}
            </Section>
        </>
    );
}
